import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import chevronRight from "../assets/img/chevron-right.svg";
import "../assets/scss/choose_creative.scss";
import "../assets/scss/choose_blogers.scss";
import MyButton from "../components/UI/MyButton";
import bloger from "../assets/img/bloger.svg"
import clock from "../assets/img/clock.svg"
import refresh from "../assets/img/refresh.svg"
import filter from "../assets/img/filter.svg"
import filterWhite from "../assets/img/filterWhite.svg"
import gptApi from "../config";

import {setBlogers as setBlogersAction} from "../store/actions/appStateAction";
import MyDisclaimer from "../components/UI/MyDisclaimer";
import {addSufixToNumber, formatCost} from "../utils/format";
import BlogerCard from "../components/BlogerCard";

const ChooseBlogers = () => {

    const [loading, setLoading] = useState(true);
    const briefCompany = useSelector((state) => state.dataAppState);
    useEffect(() => {
        console.log(briefCompany);
    }, [briefCompany]);
    const campaignId = useSelector(
        (state) => state.dataAppState.technicalTask.id
    ) || localStorage.getItem('id');
    console.log(campaignId);
    const cities = useSelector(
        (state) => state.bookCityState.data
    );
    useEffect(() => {
        console.log(cities);
    }, [cities]);
    const dispatch = useDispatch();
    const prognosPriceRef =useRef();
    const trustPriceRef = useRef();
    const navigate = useNavigate();

    const [blogers, setBlogers] = useState([]);
    const [selectedBloggers, setSelectedBlogers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPage, setCountPage] = useState([1]);
    const [addFilter, setAddFilter] = useState(false);
    const [newBlogers, setNewBlogers] = useState([]);
    const [countBlogers, setCountBlogers] = useState(2)
    const [filterBlogers, setFilterBlogers] = useState(0);
    useEffect(() => {

        getCompanyBlogers(true)
    }, [currentPage]);
    useEffect(() => {
        console.log(selectedBloggers);
    }, [selectedBloggers]);
    const getCompanyBlogers = async (reset = false) => {
        setLoading(true);
        await gptApi.get("/api-web/company-blogers?id=" + Number(campaignId) + "&page=" + currentPage).then((response) => {
            // mobile safari canvas limit is low
            const data = response.data.data?.slice(0, /webos|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()) ? 20 : 100);
            console.log(data);

            if (reset) {

                setBlogers(prev => {

                    return [...prev.filter(pr => selectedBloggers.includes(pr)),
                        ...data.filter(pr => !selectedBloggers.some(bloger => bloger.id === pr.id))
                    ]
                })

            } else
                setBlogers(prev => [...prev, ...data]);
            setLoading(false);
        });

    }
    // useEffect(() => {
    //     getCompanyBlogers();
    //
    // }, []);
    const addNewBlogers = async (countBlogers) => {

        const filter = filterBlogers === 1 && "&price_type=1" || filterBlogers === 2 && "&price_type=2" || "";
        const res = await gptApi.get("/api-web/company-blogers?id=" + Number(campaignId) + "&page=" + countBlogers + filter + "&q="+30);
        const data = res.data.data?.slice(0, /webos|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()) ? 20 : 100);
        // if (newBlogers.length + data.length > 30) {
        //     alert('Максимальное количество блогеров!');
        //     return
        // }
        setBlogers(prev =>
            [...data]
        )
    }
    const handleChooseBloger = (bloger) => {
        console.log(bloger);
        if (!selectedBloggers.includes((bloger)))
            setSelectedBlogers(prev => [...prev, bloger]);
        else
            setSelectedBlogers(prev => prev.filter(curbloger => curbloger.id !== bloger.id));
        console.log(selectedBloggers);

    }
    const state = useSelector(
        (state) => state.dataAppState
    );
    useEffect(() => {
        console.log(state);
    }, [state]);
    return (
        <div className="choose_creative">
            <div className="choose_creative_block">
                <div className="choose_creative_block_choose">
                    <h2 style={{marginBottom: '10px'}}>Выберите подходящих блогеров и перейдите к следующему этапу</h2>
                    <div className="choose_creative_block_choose_header">
                        <div className="timing">
                            <img src={clock} alt=""/>
                            <p>Это может занять несколько минут</p>
                        </div>

                        {/*<MyDisclaimer text="Это может занять несколько минут"/>*/}
                        <button
                            className="refreshBtn"
                            onClick={() => {
                                addNewBlogers(countBlogers);
                                setCountBlogers(countBlogers + 1)
                            }
                            }>
                            <img src={refresh} alt=""/>
                            Предложить других блогеров
                        </button>
                        <button
                            className={`${!addFilter ? "filterBtn" : "filterBtnSelected"}`}
                            onClick={() => setAddFilter(!addFilter)
                            }>
                            <img src={!addFilter ? filter : filterWhite} alt=""/>
                            Фильтры
                        </button>


                    </div>
                    {
                        addFilter &&
                        <div className="filterPanel">
                            <div className="filterItem roundedTop">
                                <input type="checkbox" ref={trustPriceRef} onChange={(e) => {
                                    if (e.target.checked) {
                                        setFilterBlogers(1);
                                        prognosPriceRef.current.checked = false;
                                    }

                                    else {
                                        setFilterBlogers(0);

                                    }

                                }}/>
                                <p>Блогеры с проверенной ценой</p>
                            </div>
                            <div className="filterItem roundedBot">
                                <input type="checkbox" ref={prognosPriceRef}  onChange={(e) => {
                                    if (e.target.checked) {
                                        setFilterBlogers(2);
                                        trustPriceRef.current.checked = false;
                                    }
                                    else {
                                        setFilterBlogers(0);

                                    }
                                }}/>
                                <p>Блогеры с прогнозируемой ценой</p>
                            </div>
                        </div>
                    }

                    {/*<div className="pagination">*/}
                    {/*    {*/}
                    {/*        countPage.map(page =>*/}
                    {/*            <div className={`page ${page == currentPage ? "selectedPage" : ""}`} onClick={() => setCurrentPage(page)}>{page}</div>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {
                        newBlogers.length !== 0 &&
                        <h3 style={{marginTop: '20px', fontSize: '22px'}}>Новые блогеры</h3>
                    }
                    <div className="choose_creative_block_items">
                        {
                            newBlogers.length !== 0 &&
                            newBlogers?.map(bloger => {
                                return (
                                    <BlogerCard bloger={bloger} handleChooseBloger={handleChooseBloger} cities={cities} selectedBloggers={selectedBloggers}/>
                                )
                            })

                        }
                        {loading &&
                            6 - blogers.length > 0 &&
                            new Array(6 - blogers.length).fill(null).map((item, index) => (
                                <div
                                    className={` choose_creative_block_items_item_skeleton`}
                                    key={index}
                                >
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <h3 style={{marginTop: '20px', fontSize: '22px'}}>Новые блогеры</h3>
                    <div className="choose_creative_block_items">

                        {
                            blogers?.map(bloger => {
                                return (
                                    <BlogerCard bloger={bloger} handleChooseBloger={handleChooseBloger} cities={cities} selectedBloggers={selectedBloggers}/>
                                )
                            })
                        }
                    </div>
                    <h3 style={{marginTop: '20px', fontSize: '22px'}}>Выбранные блогеры</h3>
                    <div className="choose_creative_block_items">

                        {
                            selectedBloggers?.map(bloger => {
                                return (
                                    <BlogerCard bloger={bloger} handleChooseBloger={handleChooseBloger} cities={cities} selectedBloggers={selectedBloggers}/>
                                )
                            })
                        }
                    </div>


                </div>
                <div className="choose_creative_block_buttons">
                    <span onClick={() => navigate("/select_options")}>
                        Внести изменения в ТЗ
                    </span>
                    <div>
                        <span onClick={() => navigate(-1)}>Назад</span>
                        <MyButton
                            text={"Продолжить"}
                            icon={chevronRight}
                            disabled={!setSelectedBlogers.length}
                            onClick={() => {
                                //dispatch(setCreativesAction(selectedCreatives));
                                dispatch(setBlogersAction(selectedBloggers));
                                navigate("/choose_creative");
                                // navigate("/choose_text_photo");
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        appState: state.dataAppState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBlogers: (data) => dispatch(setBlogersAction(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseBlogers);
