import {setBlogPostPhoto} from "../actions/appStateAction";

const initialState = {
  technicalTask: {},
  briefCompany: "",
  briefPost: "",
  creatives: [],
  blogers:[],
  post: null,
  photo: null,
};

const appStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TECHNICAL_TASK":
      return {
        ...state,
        technicalTask: { ...state.technicalTask, ...action.payload },
      };
    case "SET_BRIEF_COMPANY":
      return {
        ...state,
        briefCompany: action.payload,
      };
    case "SET_CREATIVES":
      return {
        ...state,
        creatives: action.payload,
      };
    case "SET_BLOGERS":
      return {
        ...state,
        blogers: action.payload,
      };
    case "SET_BLOG_POST_PHOTO":
      return {
        ...state,
        blogPostPhoto: action.payload,
      };
    case "SET_BRIEF_POST":
      return {
        ...state,
        briefPost: action.payload,
      };
    case "SET_POST":
      return {
        ...state,
        post: action.payload,
      };
    case "SET_PHOTO":
      return {
        ...state,
        photo: action.payload,
      };
    case "RESET_STORE":
      return initialState;

    default:
      return state;
  }
};

export default appStateReducer;
