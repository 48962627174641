import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import person from "../assets/img/person.jpeg";
import "../assets/scss/candidates_card.scss";
import gptApi from "../config";

const CandidatesCard = ({
    avatar_url = "",
    name = "-",
    description = "-",
    categories = [],
    subscriber = "-",
    sredniy_ohvat_posta = "-",
    city = "-",
    cpv = "-",
    cost= "0",
    ploshchadka = "-",
    nachala_proizvodstvo="",
    link = './',
    onLoad = () => { },
}) => {

    const cities = useSelector((state) => state.bookCityState.data);

    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        (async () => {
            // Avatar
            if (!avatar_url || avatar_url?.length == 0 || avatar_url.includes('.plain')) return onLoad();
            gptApi.get("/api-web/base64/?path=" + avatar_url).then((response) => {
                setAvatar(response.data.result);
                onLoad();
            });
        })();
    }, [avatar_url]);

    return (

        <div className="candidates_card">
            <img src={avatar ? avatar : person} alt="candidates" />
            <a href={link} style={{ cursor: 'pointer', width: '100%' }}>
                <div className="candidates_card_info" style={{ width: '100%' }}>
                    <h3 style={{ display: "block", marginBottom: "4px" }}>{name}</h3>
                    <p className="candidates_card_info_about">{description}</p>
                    <p style={{ display: "block", marginBottom: "4px" }}>Тематика блога</p>
                    <div className="selected_items">
                        {(Array.isArray(categories) ? categories : []).map(
                            (category) => (
                                <div className="selected_item">
                                    <span>
                                        {category}
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                    <div className="audience">
                        <div className="audience_left">
                            <span style={{ display: "block", marginBottom: "4px" }}>
                                Аудитория
                            </span>
                            <p>{subscriber} подписчиков</p>
                        </div>
                        <div className="audience_right">
                            <span style={{ display: "block", marginBottom: "4px" }}>
                                Охват публикации
                            </span>
                            <p>{nachala_proizvodstvo}</p>
                        </div>
                    </div>

                    <div className="city">
                        <div className="city_left">
                            <span style={{display: "block", marginBottom: "4px"}}>Город</span>
                            <p>{cities.find((c) => c.id == city)?.name}</p>
                        </div>
                        <div className="city_right">
                            <span style={{display: "block", marginBottom: "4px"}}>CPV</span>
                            <p>{cpv ? cpv.toFixed(1) : 0}</p>
                        </div>
                        <div className="city_right">
                            <span style={{display: "block", marginBottom: "4px"}}>Цена</span>
                            <p>{cost ? cost : 0}</p>
                        </div>

                        <div className="city_right"><span style={{display: "block", marginBottom: "4px"}}>
                            Площадка
                        </span>
                            <p>{ploshchadka
                            }</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default connect()(CandidatesCard);
