import gptApi from "../../config.js";

export const fetchBookCityRequest = () => ({
  type: 'FETCH_BOOK_CITY_REQUEST',
});

export const fetchBookCitySuccess = (data) => ({
  type: 'FETCH_BOOK_CITY_SUCCESS',
  payload: data,
});

export const fetchBookCityFailure = (error) => ({
  type: 'FETCH_BOOK_CITY_FAILURE',
  payload: error,
});

export const fetchBookCity = () => {
  return (dispatch) => {
    dispatch(fetchBookCityRequest());

    gptApi.get('/api-web/book-city')
      .then((response) => {
        dispatch(fetchBookCitySuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBookCityFailure(error));
      });
  };
};