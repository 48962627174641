import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import chevronRight from "../assets/img/chevron-right.svg";
import "../assets/scss/choose_text_photo.scss";
import MyButton from "../components/UI/MyButton";
import gptApi from "../config";
import {setBlogPostPhoto, setPhoto, setPost} from "../store/actions/appStateAction";
import MyDisclaimer from "../components/UI/MyDisclaimer";
import MyTextarea from "../components/UI/MyTextarea";
import {info} from "sass";
import {addSufixToNumber, formatCost, formatValue} from "../utils/format";
import clock from "../assets/img/clock.svg";
import arrowUp from "../assets/img/arrowUp.svg";
import arrowDown from "../assets/img/arrowDown.svg";
import textPattern from "../assets/img/textPattern.svg";
import imgPattern from "../assets/img/imgPattern.svg";
import check from "../assets/img/check.svg"

const ChooseTextPhoto = () => {
    const sliderRef = useRef(null);


    const [posts, setPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [genText, setGenText] = useState(false);
    const [genImg, setGenImg] = useState(false);
    const briefPost = useSelector((state) => state.dataAppState.briefPost);
    const blogers = useSelector((state) => state.dataAppState.blogers);
    const [blogersShow, setBlogersShow] = useState(false);
    const [currentBloger, setCurrentBloger] = useState(blogers[0])
    const campaignId = useSelector(
        (state) => state.dataAppState.technicalTask.id
    ) || localStorage.getItem('id');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [selectedCreatives, setSelectedCreatives] = useState([]);
    const [customCreative, setCustomCreative] = useState();
    const [blogInfo, setBlogInfo] = useState([])
    // const [totalInfo, setTotalInfo] = useState([[{bloger:blogers[0]}]]);
    const [totalInfo, setTotalInfo] = useState([{bloger: blogers[0]}]);

    useEffect(() => {

        const isObjectExist = totalInfo.some(subArray =>
            subArray.bloger.id === currentBloger.id
        );
        console.log(isObjectExist);

        if (!isObjectExist) {

            setTotalInfo(prevArray => {
                console.log([...prevArray, {bloger: currentBloger}]);
                return [...prevArray, {bloger: currentBloger}]

            });

        }


    }, [currentBloger]);
    useEffect(() => {

        console.log(totalInfo);
    }, [totalInfo]);
    const handleItemClick = (data) => {
        console.log(selectedCreatives)
        console.log(data);
        let currentBlog = blogInfo.find(blog => blog.bloger === currentBloger);

        if (currentBlog) {
            if (currentBlog?.post?.includes(data)) {
                currentBlog = {...currentBlog, post: currentBlog.post.filter(post => post !== data)}
                setBlogInfo(prev => {
                    const resultDelee = prev.map(currentPrev => {
                        if (currentPrev.bloger === currentBloger)
                            return currentBlog
                        else
                            return currentPrev
                    })
                    console.log(resultDelee);
                    return resultDelee
                })
            } else {
                const updateBloginfo = blogInfo.map(blog => {
                        const isPost = blog?.post ? blog?.post : [];
                        return blog.bloger === currentBloger ? {...blog, post: [...isPost, data]} : blog
                    }
                );
                console.log(updateBloginfo);
                setBlogInfo(updateBloginfo)
            }


        } else
            setBlogInfo(prev => {
                console.log([...prev, {bloger: currentBloger, post: [data]}]);
                return [...prev, {bloger: currentBloger, post: [data]}]
            })

    };
    useEffect(() => {
        const isBlog = blogInfo.some(blog => blog.bloger === currentBloger);
        if (isBlog) {
            const updateBloginfo = blogInfo.map(blog =>
                blog.bloger === currentBloger ? {...blog, creative: customCreative} : blog
            );
            setBlogInfo(updateBloginfo);
        } else
            setBlogInfo(prev => [...prev, {bloger: currentBloger, creative: customCreative}])
    }, [customCreative]);
    const location = useLocation();

    const [photos, setPhotos] = useState([]);
    const [loadingPhotos, setLoadingPhotos] = useState(false);

    const [selectedPostIndex, setSelectedPostIndex] = useState(null);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
    const [viewPhoto, setViewPhoto] = useState(false);
    const handlePhotoClick = (photo) => {
        console.log(photo);
        const isBlog = blogInfo.some(blog => blog.bloger === currentBloger);
        if (isBlog) {
            const updateBloginfo = blogInfo.map(blog =>
                blog.bloger === currentBloger ? {...blog, photo: photo} : blog
            );
            setBlogInfo(updateBloginfo);
        } else
            setBlogInfo(prev => [...prev, {bloger: currentBloger, photo: photo}])


        setSelectedPhotoIndex(photo);
    };

    const unfetchedPhotoIds = useRef([]);
    const getUnfetchedLength = () => unfetchedPhotoIds.current.length;
    const [pictures, setPictures] = useState([]);
    const generatePhotos = async () => {
        setLoadingPhotos(true);
        setGenImg(true);
        setPhotos([])
        const requests = [];

        for (let i = 0; i <= 2; i++) {
            const promise = gptApi
                .post(
                    "/api-web/picture-create",
                    JSON.stringify({description: "нарисуй картинку для поста в социальных сетях с текстом " + briefPost})
                )
                .then((response) => {
                    const id = response.data.id;
                    setTotalInfo(prev => {
                        let modify = prev;
                        modify.map(currentMod => {
                                if (currentMod.bloger === currentBloger) {
                                    if (currentMod?.photos?.length > 3)
                                        currentMod.photos = [];
                                    if (currentMod?.photos)
                                        currentMod.photos = [...currentMod.photos, response.data.url]
                                    else
                                        currentMod.photos = [response.data.url]
                                }

                            }
                        )
                        console.log(modify);


                        return modify
                    })
                    setPictures(prev => [...prev, {id: id, url: response.data.url}])
                    setPhotos((prev) => [...prev, response.data.url]);
                    unfetchedPhotoIds.current.push(id);
                    setLoadingPhotos(false);
                });

            requests.push(promise);
        }

        await Promise.all(requests);


    };
    const generatePosts = async () => {
        setLoadingPosts(true);
        setGenText(true);
        setPosts([])
        const response = await gptApi.post(
            "/api-web/make-post?id=" + campaignId,
            JSON.stringify({description: briefPost})
        );

        const posts = response.data
        setTotalInfo(prev => {
            let modify = prev;
            modify.map(currentMod => {
                    if (currentMod.bloger === currentBloger) {
                        currentMod.posts = response.data
                    }

                }
            )
            console.log(modify);
            return modify
        })
        setPosts(posts);
        setLoadingPosts(false);
    }
    useEffect(() => {

        console.log(blogInfo);
    }, [blogInfo]);
    useEffect(() => {
        console.log(pictures);
    }, [pictures]);
    const state = useSelector(
        (state) => state.dataAppState
    );
    const cities = useSelector(
        (state) => state.bookCityState.data
    );
    useEffect(() => {
        console.log(state);
    }, [state]);

    return (
        <div className="choose_text-photo">
            <div className="choose_text-photo_block">
                <div className="choose_text-photo_block_choose">
                    <h2 style={{marginBottom: '10px'}}>
                        Выберите подходящий текст и фото и перейдите к следующему этапу
                    </h2>
                    <div className="timing">
                        <img src={clock} alt=""/>
                        <p>Это может занять несколько минут</p>
                    </div>
                    <p>
                        Если ни один креатив не подошел, внесите изменение в бриф для
                        нейросети и попробуйте снова сформировать текст или фото.
                    </p>
                    <div className="mobileBlogers">
                        <h2>Сгенерируйте каждому блогеру картинку и текст</h2>

                        {
                            currentBloger &&
                            <div className={`blogers_card`}>

                                <div className="card_content">
                                    <div className="choose_blogers_card_top">
                                        <div className="choose_blogers_card_avatar">
                                            <img src={process.env.REACT_APP_BASE_URL + '/' + currentBloger.avatar_url} alt="f"/>
                                        </div>
                                        <div className="rightHeader">
                                            <div className="choose_blogers_card_header">
                                                <a href={currentBloger?.link} target={"_blank"} className="choose_blogers_card_header_link">
                                                    <h2>{currentBloger?.name}</h2>
                                                    <p className="choose_blogers_card_header_text"></p>
                                                </a>
                                            </div>
                                            <p className="title">Тематика блога</p>
                                            <div className="choose_blogers_card_content_thems">
                                                {
                                                    currentBloger.categories.map(cat => (
                                                        <p>{cat}</p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="lineGrey"/>
                                    <div className="card_content_info">
                                        <div className="blogers_card_content_info_first">
                                            <p className="title">Аудитория</p>
                                            <p className="choose_blogers_card_content_info_text">
                                                {addSufixToNumber(currentBloger?.subscriber) + " " + "подписчиков"}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="title">Охват публикации</p>
                                            <p className="choose_blogers_card_content_info_text">{addSufixToNumber(currentBloger?.nachala_proizvodstvo) + " " + "просмотров"}</p>
                                        </div>
                                    </div>
                                    <hr className="lineGrey"/>
                                    <div className="card_content_info">
                                        <div className="blogers_card_content_info_first">
                                            <p className="title">Город</p>
                                            <p className="choose_blogers_card_content_info_text">
                                                {
                                                    cities?.find(city => city.id == currentBloger.city)?.name
                                                }
                                            </p>
                                        </div>

                                        <div>
                                            <p className="title">Цена</p>
                                            <p className="choose_blogers_card_content_info_text">{currentBloger?.cost ? formatCost(currentBloger?.cost) : 0}</p>
                                        </div>
                                        <div>
                                            <p className="title">CPV</p>
                                            <p className="choose_blogers_card_content_info_text">{currentBloger?.cpv ? currentBloger?.cpv.toFixed(1) : 0}</p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        }
                        <div className="bloger_panel">
                            {
                                !blogersShow ?
                                    blogers?.map((bloger, index) => {
                                        if (index > 1)
                                            return <></>
                                        const isBlogInfo = blogInfo.find(info => info.bloger.id === bloger.id);
                                        return (
                                            <>
                                                <div className={`bloger_card ${currentBloger.id == bloger.id ? "bloger_card_selected" : ""}`} onClick={() => {
                                                    setCurrentBloger(bloger);
                                                    setSelectedCreatives([]);
                                                }}>
                                                    {bloger.name}
                                                    {
                                                        (isBlogInfo?.post && isBlogInfo?.photo)  &&
                                                        <img src={check} alt=""/>
                                                    }
                                                </div>

                                            </>
                                        )
                                    })
                                    :
                                    blogers?.map((bloger, index) => (
                                        <>
                                            <div className={`bloger_card ${currentBloger.id == bloger.id ? "bloger_card_selected" : ""}`} onClick={() => {
                                                setCurrentBloger(bloger);
                                                setSelectedCreatives([]);
                                            }}>{bloger.name}</div>

                                        </>
                                    ))


                            }
                            <div className="showBlogers">
                                <div className="showBlogersBtn"
                                     onClick={() => setBlogersShow(!blogersShow)}>
                                     <span className={`woman selected`}>
                                            {!blogersShow
                                                ? "Показать ещё выбранных блогеров"
                                                : "Скрыть список выбранных блогеров"}
                                        </span>
                                    <img src={!blogersShow ? arrowDown : arrowUp} alt=""/>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="mobileImg">
                        <button className="generane_elements" onClick={generatePhotos}>Сгенерировать
                            картинки <img src={imgPattern} alt=""/></button>
                    </div>
                    {
                        genImg &&
                        <>
                            <h3>Выбрать картинку</h3>
                            <div className="choose_text-photo_block_photos">
                                {/* Photos */}
                                {!loadingPhotos && totalInfo.find(infos =>
                                    infos.bloger.id === currentBloger.id
                                )?.photos ?
                                    totalInfo.find(infos =>
                                        infos.bloger.id === currentBloger.id
                                    ).photos.map((photo, index) => (
                                        <img
                                            src={process.env.REACT_APP_BASE_URL + "/" + photo}
                                            className={`choose_text-photo_block_photos_photo ${blogInfo.find(blog => blog.bloger === currentBloger)?.photo === photo ? "selected" : ""
                                            }`}
                                            onClick={() => handlePhotoClick(photo)}
                                            key={index}
                                            alt="imgs"
                                        />
                                    ))
                                    :
                                    <></>
                                }


                                {/* Skeleton */}
                                {loadingPhotos &&
                                    8 - photos.length > 0 &&
                                    new Array(3 - photos.length).fill(null).map((photo, index) => (
                                        <div
                                            className={`choose_text-photo_block_photos_photo choose_text-photo_block_photos_photo_skeleton`}
                                            key={index}
                                        >
                                            {" "}
                                            <div className="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </>
                    }
                    <div className="mobileText">
                        <button className="generane_elements" onClick={generatePosts}>Сгенерировать
                            текст <img src={textPattern} alt=""/></button>
                    </div>
                    {
                        genText &&
                        <>
                            <h3>Выбрать текст</h3>
                            <div className="choose_text-photo_block_items">
                                {/* Posts */}
                                {totalInfo.find(infos =>
                                    infos.bloger.id === currentBloger.id
                                )?.posts ?
                                    totalInfo.find(infos =>
                                        infos.bloger.id === currentBloger.id
                                    ).posts.map((item, index) => (
                                        <div
                                            className={`choose_creative_block_items_item 
                                            ${blogInfo.find(blog => blog.bloger === currentBloger)?.post?.some(
                                                (selected) => item === selected
                                            )
                                                ? "selected"
                                                : ""
                                            }`}
                                            key={index}
                                            onClick={() => handleItemClick(item)}
                                        >
                                            <div className={`textIndex
                                                ${blogInfo.find(blog => blog.bloger === currentBloger)?.post?.some(
                                                (selected) => item === selected
                                            )
                                                ? "selectedTextIndex"
                                                : ""
                                            }`}>
                                                {index + 1}
                                            </div>
                                            <p>{item}</p>
                                        </div>
                                    ))
                                    :
                                    <></>

                                }
                                {loadingPosts &&
                                    new Array(6).fill(null).map((item, index) => (
                                        <div
                                            className={`choose_text-photo_block_items_item choose_text-photo_block_items_item_skeleton`}
                                            key={index}
                                        >
                                            <div className="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            <p>
                                Ваш креатив (если вам не подошел ни один из предложенных):
                            </p>
                            <br/>
                            <MyTextarea
                                className={'choose_creative_textarea'}
                                value={customCreative}
                                style={{
                                    'width': '100%'
                                }}
                                onChange={(e) => {

                                    setCustomCreative(e.target.value)
                                }}
                            />

                        </>
                    }
                </div>

                {/*<div className="choose_creative_block_choose">*/}
                {/*    <p>*/}
                {/*        Результат:*/}
                {/*    </p>*/}

                {/*    {*/}
                {/*        blogInfo.find(blog => blog.bloger === currentBloger)?.post?.map(creative => (*/}
                {/*            <p>*/}
                {/*                {creative}*/}
                {/*            </p>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*    <p>*/}
                {/*        {customCreative}*/}
                {/*    </p>*/}

                {/*</div>*/}
                <div className="choose_text-photo_block_buttons">
                    <span onClick={() => navigate("/select_options")}>
                        Внести изменения в ТЗ
                    </span>
                    <div>
                        <span onClick={() => navigate(-1)}>Назад</span>
                        <div style={{width: "200px"}}>
                            <MyButton
                                text={"Продолжить"}

                                icon={chevronRight}
                                onClick={() => {
                                    dispatch(setPost(selectedCreatives.join('\n') + customCreative));
                                    dispatch(setPhoto(selectedPhotoIndex));
                                    dispatch(setBlogPostPhoto(blogInfo));
                                    navigate("/done");
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="bloger_view">

                <div className="deckBlogers">
                    <h2>Сгенерируйте каждому блогеру картинку и текст</h2>

                    {
                        currentBloger &&
                        <div className={`blogers_card`}>

                            <div className="card_content">
                                <div className="choose_blogers_card_top">
                                    <div className="choose_blogers_card_avatar">
                                        <img src={process.env.REACT_APP_BASE_URL + '/' + currentBloger.avatar_url} alt="f"/>
                                    </div>
                                    <div>
                                        <div className="choose_blogers_card_header ">
                                            <a href={currentBloger?.link} target={"_blank"} className="choose_blogers_card_header_link">
                                                <h2>{currentBloger?.name}</h2>
                                                <p className="choose_blogers_card_header_text"></p>
                                            </a>
                                        </div>
                                        <p className="title">Тематика блога</p>
                                        <div className="choose_blogers_card_content_thems">
                                            {
                                                currentBloger.categories.map(cat => (
                                                    <p>{cat}</p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <hr className="lineGrey"/>
                                <div className="card_content_info">
                                    <div className="blogers_card_content_info_first">
                                        <p className="title">Аудитория</p>
                                        <p className="choose_blogers_card_content_info_text">
                                            {addSufixToNumber(currentBloger?.subscriber) + " " + "подписчиков"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="title">Охват публикации</p>
                                        <p className="choose_blogers_card_content_info_text">{addSufixToNumber(currentBloger?.nachala_proizvodstvo) + " " + "просмотров"}</p>
                                    </div>
                                </div>
                                <hr className="lineGrey"/>
                                <div className="card_content_info">
                                    <div className="blogers_card_content_info_first">
                                        <p className="title">Город</p>
                                        <p className="choose_blogers_card_content_info_text">
                                            {
                                                cities?.find(city => city.id == currentBloger.city)?.name
                                            }
                                        </p>
                                    </div>

                                    <div>
                                        <p className="title">Цена</p>
                                        <p className="choose_blogers_card_content_info_text">{currentBloger?.cost ? formatCost(currentBloger?.cost) : 0}</p>
                                    </div>
                                    <div>
                                        <p className="title">CPV</p>
                                        <p className="choose_blogers_card_content_info_text">{currentBloger?.cpv ? currentBloger?.cpv.toFixed(1) : 0}</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    }
                    <div className="bloger_panel">
                        {
                            !blogersShow ?
                                blogers?.map((bloger, index) => {
                                    if (index > 1)
                                        return <></>
                                    const isBlogInfo = blogInfo.find(info => info.bloger.id === bloger.id);
                                    return (
                                        <>
                                            <div className={`bloger_card ${currentBloger.id == bloger.id ? "bloger_card_selected" : ""}`} onClick={() => {
                                                setCurrentBloger(bloger);
                                                setSelectedCreatives([]);
                                            }}>
                                                {bloger.name}
                                                {
                                                    (isBlogInfo?.post && isBlogInfo?.photo)  &&
                                                    <img src={check} alt=""/>
                                                }

                                            </div>

                                        </>
                                    )
                                })
                                :
                                blogers?.map((bloger, index) => {
                                    const isBlogInfo = blogInfo.find(info => info.bloger.id === bloger.id);
                                    return (
                                        <>
                                            <div className={`bloger_card ${currentBloger.id == bloger.id ? "bloger_card_selected" : ""}`} onClick={() => {
                                                setCurrentBloger(bloger);
                                                setSelectedCreatives([]);
                                            }}>
                                                {bloger.name}
                                                {
                                                    (isBlogInfo?.post && isBlogInfo?.photo)  &&
                                                    <img src={check} alt=""/>
                                                }
                                            </div>

                                        </>
                                    )
                                })


                        }
                        <div className="showBlogers">
                            <div className="showBlogersBtn"
                                 onClick={() => setBlogersShow(!blogersShow)}>
                                     <span className={`woman selected`}>
                                            {!blogersShow
                                                ? "Показать ещё выбранных блогеров"
                                                : "Скрыть список выбранных блогеров"}
                                        </span>
                                <img src={!blogersShow ? arrowDown : arrowUp} alt=""/>
                            </div>


                        </div>
                    </div>

                    <button className="generane_elements" onClick={generatePhotos}>Сгенерировать
                        картинки <img src={imgPattern} alt=""/></button>
                    <button className="generane_elements" onClick={generatePosts}>Сгенерировать
                        текст <img src={textPattern} alt=""/></button>
                </div>
            </div>
        </div>
    )
        ;
};

export default connect()(ChooseTextPhoto);
