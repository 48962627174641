export const setTechnicalTask = (data) => ({
  type: "SET_TECHNICAL_TASK",
  payload: data,
});

export const setBriefCompany = (data) => ({
  type: "SET_BRIEF_COMPANY",
  payload: data,
});

export const setCreatives = (data) => ({
  type: "SET_CREATIVES",
  payload: data,
});
export const setBlogers = (data) => ({
  type: "SET_BLOGERS",
  payload: data,
});
export const setBlogPostPhoto = (data) => ({
  type: "SET_BLOG_POST_PHOTO",
  payload: data,
});

export const setBriefPost = (data) => ({
  type: "SET_BRIEF_POST",
  payload: data,
});

export const setPost = (data) => ({
  type: "SET_POST",
  payload: data,
});

export const setPhoto = (data) => ({
  type: "SET_PHOTO",
  payload: data,
});

export const resetStore = () => ({
  type: "RESET_STORE",
  payload: null,
});
