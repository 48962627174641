import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import "../assets/img/logo.png";
import mailRoutesImage from "../assets/img/mail_routes.svg";
import mailStartImage from "../assets/img/mail_start.svg";
import telegramRoutesImage from "../assets/img/telegram_routes.svg";
import telegramStartImage from "../assets/img/telegram_start.svg";
import "../assets/scss/nav_bar.scss";

const NavBar = () => {
    const location = useLocation();
    const isInStartRoute = location.pathname === "/";
    const isResultRoute = location.pathname == "/download";
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    console.log(location.pathname, isResultRoute);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (

        <div className={`nav_bar ${isInStartRoute ? "" : "nav_bar_background"} ${isResultRoute ? "nav_none" : ""}`}>
            <div className="nav_bar_links">
                <Link target="_blank" to="https://bestbloggers.ru/">
                    <img src={require("../assets/img/logo.png")} alt="logo"/>
                </Link>
                <Link to="/">
                    Главная
                </Link>
            </div>
            <div className="nav_bar_right_block">
                <a href="mailto:ads@trendmedia.agency">
                    {
                        window.innerWidth > 768 ?
                            <div
                                className={"nav_bar_button"
                                }
                            >
                                <img
                                    src={
                                        mailRoutesImage
                                    }
                                    alt="mail"
                                />
                                <a
                                    href="mailto:ads@trendmedia.agency"
                                    className={"nav_bar_mail"
                                    }
                                >
                                    Написать нам
                                </a>
                            </div>
                            :
                            <div className="mobileMail">
                                <img
                                    src={
                                        mailRoutesImage
                                    }
                                    alt="telegram"
                                />
                            </div>
                    }
                </a>
                <a href="https://t.me/TrendMediaChannel">
                    {
                        window.innerWidth > 768 ?
                            <div
                                className={"nav_bar_button_telegram"
                                }
                            >
                                <img
                                    src={
                                        telegramRoutesImage
                                    }
                                    alt="telegram"
                                />
                                <p

                                    className={ "nav_bar_telegram"
                                    }
                                >
                                    Telegram
                                </p>
                            </div>
                            :
                            <div className="mobileTg">
                                <img
                                    src={
                                        telegramRoutesImage
                                    }
                                    alt="telegram"
                                />
                            </div>
                    }
                </a>
            </div>
        </div>
    );
};

export default NavBar;
