import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sendImg from "../assets/img/sendImg.svg";
import DownloadIcon from "../assets/img/download.svg";
import "../assets/scss/done.scss";
import MyButton from "../components/UI/MyButton";
import FeedbackForm from "../components/FeedbackForm";

const Done = () => {
    const { technicalTask, creatives, post, photo } = useSelector(
        (state) => state.dataAppState
    );
    const state = useSelector(
        (state) => state.dataAppState
    );
    useEffect(() => {
        console.log(state);
    }, [state]);
    const [openForm, setOpenForm] = useState(false)
    return (
        <div className="done">
            {
                openForm &&
                (
                    <div className={"form"}>
                        <FeedbackForm onClose={() => {setOpenForm(false)}}/>
                    </div>
                )
            }
            <div className="done_block">
                <div className="done_block_inner_blocks">
                    <h2>
                        Поздравляем! Ваша рекламная кампания уже готова, осталось только
                        скачать ее.
                    </h2>
                    <p>
                        Если вам понадобится более детальная проработка рекламной кампании,
                        вы всегда можете обратиться к нашему блогерскому агентству{" "}
                        <a target="_blank" href="https://bestbloggers.ru/" style={{color:"#2961f3", textDecoration:"underline"}}>bestbloggers.ru</a>
                    </p>
                    <div className="done_block_skype-block">
                        <div className="done_block_skype-block_icon">
                            <img
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                src={process.env.REACT_APP_BASE_URL + "/" + technicalTask.logo}
                                alt={`Логотип бренда ${technicalTask.brand}`}
                            />
                        </div>
                        <p>{technicalTask.brand}</p>
                        <div className="done_block_rows">{technicalTask.task}</div>
                        <MyButton className={"buttonBlue"} icon={sendImg} text={"Напишите нам"} onClick={()=>{setOpenForm(true)}} />
                        <div className={"form-messsage"}>Нашли ошибку? Есть идея? Напишите нам</div>
                    </div>

                    <Link to={"/download"}>
                        <MyButton text={"Скачать"} icon={DownloadIcon} />
                    </Link>
                </div>
            </div>
            <a target="_blank" href="https://t.me/TrendMediaChannel" className="done_telegram">
                <p>Мы в социальных сетях!</p>
                <img src={require("../assets/img/telegram.png")} alt="telegram" />
            </a>
        </div>
    );
};

export default Done;
