import React from "react";
import ReactSlider from "react-slider";
import "../../assets/scss/my_slider.scss";

const MySlider = ({
  min = 0,
  max = 100,
  minDistance = 1,
  onChange,
  value = [0, 100],
  style = {},
}) => {
  return (
    <div style={style}>
      <ReactSlider
        className={"slider"}
        thumbClassName={"slider__thumb"}
        min={min}
        max={max}
        // minDistance={minDistance}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default MySlider;
