import React, { useDeferredValue, useMemo } from "react";
import "../../assets/scss/my_input.scss";
import { formatValue } from "../../utils/format";

const MyTextarea = ({
  value,
  onChange,
  placeholder,
  style,
  type = "text",
  children
}) => {

  return (
    <>
      {children && (
        <div style={{ position: "relative" }}>
            <span className="input_title">{children}</span>
            <textarea
                className="my_input"
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={style}
            >
            </textarea>
        </div>
      )}
      {!children && (
        <textarea
            className="my_input"
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            style={style}
        >
        </textarea>
      )}
    </>
  );
};

export default MyTextarea;
