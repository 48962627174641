import gptApi from "../../config.js";

export const fetchBookAreaRequest = () => ({
  type: 'FETCH_BOOK_AREA_REQUEST',
});

export const fetchBookAreaSuccess = (data) => ({
  type: 'FETCH_BOOK_AREA_SUCCESS',
  payload: data,
});

export const fetchBookAreaFailure = (error) => ({
  type: 'FETCH_BOOK_AREA_FAILURE',
  payload: error,
});

export const fetchBookArea = () => {
  return (dispatch) => {
    dispatch(fetchBookAreaRequest());

    gptApi.get('/api-web/book-area')
      .then((response) => {
        dispatch(fetchBookAreaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBookAreaFailure(error));
      });
  };
};