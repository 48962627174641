import React, {useState} from "react";
import "../../assets/scss/my_input_blur_placeholder.scss";
import { formatValue } from "../../utils/format";

const MyInputBlurPlaceholder = ({
    placeholder,
    style,
    inputValue,
    setInputValue,
    type = 'string'
}) => {
    const [tempValue, setTempValue]=useState(inputValue);
    const handleChange = (event) => {
        setTempValue(event.target.value);
        setTimeout(() => {
            setInputValue(event.target.value);
            console.log('Отправить запрос на бэкенд с запросом:');
        }, 1000);

    };

    const handleBlur = () => {
        if (!tempValue) {
            setTempValue("");
            setTimeout(() => {
                setInputValue("");
                console.log('Отправить запрос на бэкенд с запросом:');
            }, 1000);
        }
    };

    const inputStyle =
        placeholder === "Город" || placeholder === "Количество блогеров для проекта"
            ? { paddingTop: "14px", paddingBottom: "14px" }
            : {};


    const formattedNumberValue = Number(tempValue) == 0
        ? tempValue
        : formatValue(tempValue?.replaceAll(" ", ""));

    return (
        <div className={`form-group ${tempValue ? "active" : ""}`} style={style}>
            <label htmlFor="inputField">{placeholder}</label>
            <input
                id="inputField"
                value={type == 'number' ? formattedNumberValue : tempValue

                }
                onChange={handleChange}
                onBlur={handleBlur}
                style={inputStyle}
            />
        </div>
    );
};

export default MyInputBlurPlaceholder;
