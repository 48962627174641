import React from "react";
import "../../assets/scss/my_button.scss";

const MyButton = ({ text, icon, disabled, onClick, className }) => {
  return (
    <button
      className={`my_button ${disabled && "my_button--disabled"}  ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {icon && <img src={icon} alt="Icon" />}
    </button>
  );
};

export default MyButton;
