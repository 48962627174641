export function numberWithSpaces(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1 $2");

  return x;
}

export function formatValue(value) {
  // console.log(value, numberWithSpaces(value));
  if (!Number.isNaN(Number(value))) {
    return numberWithSpaces(value);
  } else return value;
}
export function formatCost(value) {

 return ` ${Number(value).toLocaleString('ru-RU', { useGrouping: true, minimumFractionDigits: 0 })} ₽`;
}
export function addSufixToNumber(number) {
  const suffixes = ["", "тыс.", "млн.", "млрд.", "трлн.", "квадр.", "квинт."]; // Суффиксы для различных величин
  const suffixIndex = Math.floor(((""+number).length-1)/3); // Определяем индекс суффикса
  const formattedNumber = number / Math.pow(1000, suffixIndex); // Делим число на соответствующую степень 1000
  return formattedNumber.toFixed(2).replace(/\.00$/, '') + ' ' + suffixes[suffixIndex]; // Округляем число до 2 знаков после запятой и добавляем соответствующий суффикс
}
