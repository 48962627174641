import gptApi from "../../config.js";

export const fetchBookCategoryRequest = () => ({
  type: 'FETCH_BOOK_CATEGORY_REQUEST',
});

export const fetchBookCategorySuccess = (data) => ({
  type: 'FETCH_BOOK_CATEGORY_SUCCESS',
  payload: data,
});

export const fetchBookCategoryFailure = (error) => ({
  type: 'FETCH_BOOK_CATEGORY_FAILURE',
  payload: error,
});

export const fetchBookCategory = () => {
  return (dispatch) => {
    dispatch(fetchBookCategoryRequest());

    gptApi.get('/api-web/book-category')
      .then((response) => {
        console.log(response.data);
        dispatch(fetchBookCategorySuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBookCategoryFailure(error));
      });
  };
};