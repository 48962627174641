import React, {useEffect, useState} from 'react';
import {addSufixToNumber} from "../utils/format";
import inst from "../assets/img/inst.svg"
import vk from "../assets/img/VK.svg"
import tg from "../assets/img/Telegram.svg"
import youtube from "../assets/img/YouTube.svg"
import gptApi from "../config";
import person from "../assets/img/person.jpeg"

function BlogerCard(
    {
        handleChooseBloger,
        selectedBloggers,
        bloger,
        cities,
        backAvatar = false,
        setEndLoadCount
    }
) {
    const [avatar, setAvatar] = useState(null);
    const [loadAvatar, setLoadAvatar] = useState(false)
    let price;
    let isRealPrice;
    if (bloger.adv_price_calc && !bloger.adv_price) {
        isRealPrice = "ПРОГНОЗИРУЕМАЯ"
        price = bloger.adv_price_calc;
    } else {
         price = bloger.adv_price;
         isRealPrice = "РЕАЛЬНАЯ";
    }


    function checkImage(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => reject(false);
            img.src = url;
        });
    }

    useEffect(() => {
        const imageUrl = `${process.env.REACT_APP_BASE_URL}/${bloger.avatar_url}`;
        checkImage(imageUrl)
            .then(() => setLoadAvatar(true))
            .catch(() => setLoadAvatar(false))
        if (backAvatar)
            (async () => {
                gptApi.get("/api-web/base64/?path=" + bloger?.avatar_url).then((response) => {

                    setAvatar(response.data.result);
                    setEndLoadCount(prev => prev + 1);
                });
            })();
    }, []);
    return (
        <div className={`choose_blogers_card ${selectedBloggers && selectedBloggers.includes(bloger) ? 'selected_bloger' : ""}`} onClick={() => handleChooseBloger ? handleChooseBloger(bloger) : {}}>
            <div className="choose_blogers_card_header">
                <div className="choose_blogers_card_avatar">
                    {
                        loadAvatar ?
                            <img src={avatar ? avatar : `${process.env.REACT_APP_BASE_URL}/${bloger.avatar_url}`}
                                 onLoad={() => setLoadAvatar(true)}
                                 alt="f"/>
                            :
                            <img src={person} alt=""/>
                    }
                </div>
                <div className="rightHeader">
                    {
                        selectedBloggers &&
                        <input className="checkBloger"
                               checked={selectedBloggers.includes(bloger)}
                               type="checkbox"/>
                    }
                    <div style={{display: "flex", gap: "10px"}}>
                        <a href={bloger?.link} target={"_blank"} className="choose_blogers_card_header_link">
                            <h2>{bloger?.name}</h2>
                        </a>
                        <div className="socialLinks">
                            {
                                bloger?.ploshchadka_id == 1 && <img src={youtube} alt=""/>
                            }
                            {
                                bloger?.ploshchadka_id == 2 && <img src={tg} alt=""/>
                            }
                            {
                                bloger?.ploshchadka_id == 3 && <img src={vk} alt=""/>
                            }
                            {
                                bloger?.ploshchadka_id == 4 && <img src={inst} alt=""/>
                            }


                        </div>
                    </div>
                    <p className="title">Тематика блога</p>
                    <div className="choose_blogers_card_content_thems">
                        {
                            bloger.categories.map(cat => (
                                <p>{cat}</p>
                            ))
                        }
                    </div>
                </div>

            </div>
            <div className="choose_blogers_card_content">
                <hr className="line"/>
                <div className="choose_blogers_card_content_bottom">
                    <div className="choose_blogers_card_content_info">
                        <div className="choose_blogers_card_content_info_first">
                            <p className="title">Аудитория</p>
                            <p className="choose_blogers_card_content_info_text">
                                {addSufixToNumber(bloger?.subscriber) + " " + "подписчиков"}
                            </p>
                        </div>

                        <div>
                            <p className="title">Охват публикации</p>
                            <p className="choose_blogers_card_content_info_text">{addSufixToNumber(bloger?.nachala_proizvodstvo) + " " + "просмотров"}</p>
                        </div>
                    </div>
                    <hr className="line lineBottom"/>
                    <div className="choose_blogers_card_content_info">
                        <div className="choose_blogers_card_content_info_first">
                            <p className="title">Город</p>
                            <p className="choose_blogers_card_content_info_text">{
                                cities?.find(city => city.id == bloger.city)?.name
                            }</p>
                        </div>
                        <div className="PriceCpv">
                            <div>
                                <div className="price">
                                    <p className="title">Цена </p>
                                    <p className={`title ${isRealPrice == "ПРОГНОЗИРУЕМАЯ" ? "notReal" : "real"}`}>{isRealPrice}</p>
                                </div>
                                <p className="choose_blogers_card_content_info_text">{price} ₽</p>
                            </div>
                            <div className="cpv">
                                <p className="title">CPV</p>
                                <p className="choose_blogers_card_content_info_text">{Number(bloger?.cpv) ? Number(bloger?.cpv).toFixed(1) : 0}</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogerCard;