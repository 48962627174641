import "canvas-particle-network";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/start.scss";
import MyButton from "../components/UI/MyButton";
import MyInputBlurPlaceholder from "../components/UI/MyInputBlurPlaceholder";

const Start = () => {
    useEffect(() => {
        const initializeParticleAnimation = () => {
            const options = {
                particleColor: "#e37575",
                interactive: true,
                speed: "medium",
                density: "high",
            };
            const particleCanvas = new window.ParticleNetwork(
                document.getElementById("particle-canvas"),
                options
            );
        };

        initializeParticleAnimation();
    }, []);

    const [campaign, setCampaign] = useState("");

    return (
        <div className="start">
            <div className="start_left-block">
                <p className="start_text">
                    Создай рекламную кампанию с блогерами в несколько кликов с помощью
                    нейросети от{" "}
                    <a target="_blank" href="https://bestbloggers.ru/" target="blank">
                        BestBloggers.ru
                    </a>{" "}
                </p>
                <div className="start_search_block">
                    <MyInputBlurPlaceholder
                        inputValue={campaign}
                        setInputValue={setCampaign}
                        placeholder={"Введите название рекламной кампании"}
                        style={{ width: "62%" }}
                    />
                    <Link to={"/select_options"} state={{ data:  campaign  }}>
                        <MyButton text={"Начать"} disabled={!campaign}/>
                    </Link>
                </div>
            </div>
            <div id="particle-canvas"></div>
        </div>
    );
};

export default Start;
