import React, { useDeferredValue, useMemo } from "react";
import "../../assets/scss/my_input.scss";
import { formatValue } from "../../utils/format";

const MyInput = ({
  value,
  onChange,
  placeholder,
  style,
  type = "text",
  children,
}) => {
  const deffered = useDeferredValue(value);

  const formattedValue = useMemo(
    () => formatValue(value.toString().replaceAll(" ", "")),
    [value]
  );

  return (
    <>
      {children && (
        <div style={{ position: "relative" }}>
          <span className="input_title">{children}</span>
          <input
            className="my_input"
            type={type}
            value={formattedValue}
            onChange={(e) =>
              onChange(
                deffered == "0" ? e.target.value.slice(1) : e.target.value
              )
            }
            placeholder={placeholder}
            style={style}
          />
        </div>
      )}
      {!children && (
        <input
          className="my_input"
          type={type}
          value={formattedValue}
          onChange={(e) =>
            onChange(deffered == "0" ? e.target.value.slice(1) : e.target.value)
          }
          placeholder={placeholder}
          style={style}
        />
      )}
    </>
  );
};

export default MyInput;
