import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import chevronRight from "../assets/img/chevron-right.svg";
import info from "../assets/img/info.svg"
import "../assets/scss/formulate_brief_product.scss";
import MyButton from "../components/UI/MyButton";
import { setBriefPost } from "../store/actions/appStateAction";

const FormulateBriefProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [brief, setBrief] = useState("");
  const state = useSelector(
      (state) => state.dataAppState
  );
  useEffect(() => {
    console.log(state);
  }, [state]);
  return (
    <div className="formulate_brief_product">
      <div className="formulate_brief_product_block">
        <div className="formulate_brief_product_block_brif">
            <h2>
              Сформулируйте бриф для нейросети на создание рекламных публикаций
              блогеров с вашим продуктом <img src={info} className="check_info" onClick={()=>setOpenModal(!openModal)}/>

            </h2>

          {
              openModal &&
              <div className="modal_wrapper">
                <div className="modal_info">
                  <h2>Примеры запросов к нейросети</h2>
                  <div className="modal_info_text">
                    <p>1. Я хочу чтобы вы выступили в роли маркетолога...</p>
                    <p>2. Сгенерируйте заголовок для статьи о технологических трендах в будущем...</p>
                    <p>3. Напишите увлекательное и креативное описание для рекламы предстоящего музыкального фестиваля...</p>
                    <p>4. Создайте оригинальный пост для Instagram, который привлечет внимание подписчиков...</p>
                  </div>

                </div>
              </div>

          }
          <textarea
            placeholder={
              "Вставьте пример поста, из которого нейросеть сделает посты для блогеров"
            }
            value={brief}
            onChange={(e) => setBrief(e.target.value)}
          />
        </div>
        <div className="formulate_brief_product_block_buttons">
          <span onClick={() => navigate("/select_options")}>
            Внести изменения в ТЗ
          </span>
          <div>
            <span onClick={() => navigate(-1)}>Назад</span>
            <Link to={"/choose_text_photo"}>
              <MyButton
                text={"Продолжить"}
                icon={chevronRight}
                disabled={brief.length < 8}
                onClick={() => {
                  dispatch(setBriefPost(brief));
                  navigate("/choose_text_photo");
                }}
              />
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

export default connect()(FormulateBriefProduct);
