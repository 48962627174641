import { combineReducers } from "redux";
import appStateReducer from "./appStateReducer.js";
import bookAreaReducer from "./bookAreaReducer.js";
import bookCategoryReducer from "./bookCategoryReducer.js";
import bookCityReducer from "./bookCityReducer.js";

const rootReducer = combineReducers({
  bookAreaState: bookAreaReducer,
  bookCityState: bookCityReducer,
  bookCategoryState: bookCategoryReducer,
  dataAppState: appStateReducer,
});

export default rootReducer;
