import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import chevronRight from "../assets/img/chevron-right.svg";
import "../assets/scss/formulate_brief_company.scss";
import MyButton from "../components/UI/MyButton";
import { setBriefCompany } from "../store/actions/appStateAction";

const FormulateBriefCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [brief, setBrief] = useState("");

  return (
    <div className="formulate_brief_company">
      <div className="formulate_brief_company_block">
        <div className="formulate_brief_company_block_brif">
          <h2>
            Сформулируйте бриф для нейросети на создание креативной концепции
            рекламной кампании
          </h2>
          <textarea
            placeholder={
              "Придумай рекламную кампанию для рекламного агентства, которое специализируется на рекламе у блогеров и делает это эффективнее и дешевле всех схожих компаний."
            }
            text={brief}
            onChange={(e) => setBrief(e.target.value)}
          />
        </div>
        <div className="formulate_brief_company_block_buttons">
          <span onClick={() => navigate("/select_options")}>
            Внести изменения в ТЗ
          </span>
          <div>
            <span onClick={() => navigate(-1)}>Назад</span>
            {/* <Link> */}
            <MyButton
              text={"Продолжить"}
              icon={chevronRight}
              disabled={brief.length < 8}
              onClick={() => {
                dispatch(setBriefCompany(brief));
                navigate("/choose_creative");
              }}
            />
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataBriefCompany: state.dataAppState.briefCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBriefCompany: (data) => dispatch(setBriefCompany(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulateBriefCompany);
