import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import ChooseCreative from "./views/ChooseCreative";
import ChooseTextPhoto from "./views/ChooseTextPhoto";
import Done from "./views/Done";
import Download from "./views/Download";
import FormulateBriefCompany from "./views/FormulateBriefCompany";
import FormulateBriefProduct from "./views/FormulateBriefProduct";
import SelectOptions from "./views/SelectOptions";
import Start from "./views/Start";
import ChooseBlogers from "./views/ChooseBlogers";
import ViewResult from "./views/ViewResult";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/select_options" element={<SelectOptions />} />
        <Route
          path="/formulate_brief_company"
          element={<FormulateBriefCompany />}
        />
        <Route path="/choose_creative" element={<ChooseCreative />} />
        <Route path="/choose_blogers" element={<ChooseBlogers />} />
        <Route
          path="/formulate_brief_product"
          element={<FormulateBriefProduct />}
        />
        <Route path="/choose_text_photo" element={<ChooseTextPhoto />} />
        <Route path="/done" element={<Done />} />
        <Route path="/download" element={<Download />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
