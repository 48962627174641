import html2pdf from "html2pdf.js/dist/html2pdf.bundle.min.js";
import {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import instagramIcon from "../assets/img/instagram.svg";
import scooterIcon from "../assets/img/scooter_icon.svg";
import "../assets/scss/download.scss";
import CandidatesCard from "../components/CandidatesCard";
import gptApi from "../config";
import {resetStore} from "../store/actions/appStateAction";
import PDFMerger from "pdf-merger-js";
import BlogerCard from "../components/BlogerCard";

const ViewResult = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cities = useSelector(
        (state) => state.bookCityState.data
    );
    const {technicalTask, creatives, post, photo} = useSelector(
        (state) => state.dataAppState
    );


    const campaignId = useSelector(
        (state) => state.dataAppState.technicalTask.id
    );
    const bloggersAr = useSelector(
        (state) => state.dataAppState.blogers
    );
    const blogInfo = useSelector(state => state.dataAppState.blogPostPhoto);
    console.log(blogInfo);
    const [downloadedAvatarsCount, setDownloadedAvatarsCount] = useState(0);
    const [bloggers, setBloggers] = useState([]);
    const [bloggersLoaded, setBloggersLoaded] = useState(false);
    useEffect(() => {
        setBloggers(bloggersAr)
    }, [bloggersAr]);
    useEffect(() => {
        gptApi.get("/api-web/company-blogers?id=" + campaignId).then((response) => {
            // mobile safari canvas limit is low
            const data = response.data.data?.slice(0, /webos|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()) ? 20 : 100);

            //setBloggers(data);
            setBloggersLoaded(true);
        });
    }, []);

    const platforms = useSelector((state) => state.bookAreaState.data);


    console.log('download.bloggers.length: ', bloggers?.length);

    // !! HTML2PDF CAN HANDLE ONLY LOCAL OR BASE64 IMAGES!!
    const getPdf = async () => {
        console.log('height:', document.body.scrollHeight)

        function calculateHeight() {
            // Calculate minimum remaining space in the end of pdf

            const pairs = [];

            for (let i = 4; i <= 10; i++) {
                const divider = (800 * i);
                const remaining = document.body.scrollHeight % divider;

                const pair = {
                    divider,
                    remaining
                }
                pairs.push(pair);
            }

            pairs.sort((a, b) => a.remaining - b.remaining)

            return pairs[0].divider

        }

        const calculatedHeight = document.body.scrollHeight;// > 28000 ? 14000 : 5000

        const filename = `Рекламная-кампания-${technicalTask.brand}.pdf`;

        const opt = {
            margin: 0,
            filename,
            image: {type: "jpeg", quality: 10},
            pagebreak: {avoid: '.blogger-avoid'},
            html2canvas: {
                width: 1080,
                height: document.body.scrollHeight,
                scale: 1,
                dpi: 192,
                letterRendering: true,
                useCORS: true,
            },
            jsPDF: {
                unit: "px",
                format: [1080, calculatedHeight],
                orientation: "portrait",
                autoPaging: 'text',
                compress: true,


            },
            enableLinks: true
        }
        console.log(1)

        console.log('download.getPdf-2opt')
        html2pdf().set(opt).from(document.getElementById("download")).save();
        // }

    };

    useEffect(() => {
        console.log(photo);
        console.log(technicalTask.logo);
    }, [photo, technicalTask.logo]);
    const [bg, setBg] = useState({});

    function isInstagramBloggers() {
        return bloggers?.some(
            ({ploshchadka_id}) =>
                ploshchadka_id ==
                platforms.find((platform) => platform.name == "Instagram").id
        );
    }

    function getBloggerForPost() {
        if (bloggers?.length == 0) return null;

        if (
            bloggers?.some(
                ({ploshchadka_id}) =>
                    ploshchadka_id ==
                    platforms.find((platform) => platform.name == "Instagram").id
            )
        ) {
            return bloggers.filter(
                ({ploshchadka_id}) =>
                    ploshchadka_id ==
                    platforms.find((platform) => platform.name == "Instagram").id
            )[0];
        } else return bloggers[0];
    }

    const [logo, setLogo] = useState("");
    const [newPhoto, setNewPhoto] = useState([]);
    useEffect(() => {
        console.log(technicalTask)

        if (!Number.isInteger(technicalTask.selectedColorIndex)) return;
        (async () => {
            // LOGO

            gptApi
                .get("/api-web/base64/?path=" + technicalTask.logo)
                .then((response) => setLogo(response.data.result));
            blogInfo.map(blog => {

                if (blog?.photo)
                    gptApi
                        .get("/api-web/base64/?path=" + blog?.photo)
                        .then((response) => {
                            console.log(response);
                            setNewPhoto(prev => [...prev, {
                                id: blog.bloger.id,
                                photo: response.data.result
                            }])
                        })


            })
            // BG


            const bg =
                technicalTask.backgroundColors[11];

            if (bg?.includes("uploads")) {
                gptApi
                    .get("/api-web/base64/?path=" + bg)
                    .then((response) =>
                        setBg({background: `url(${response.data.result})`})
                    );
            } else setBg({background: bg});
        })();
    }, [technicalTask]);
    useEffect(() => {
        console.log(newPhoto);
    }, [newPhoto]);
    useEffect(() => {

        let intervalId;
        console.log("logo", logo, "bloggersLoaded: ", bloggersLoaded, "bg", bg)
        intervalId = setInterval(async () => {
            if (!logo || !bloggersLoaded || !bg) {
                return;
            } else {
                clearInterval(intervalId);
                console.log('download.useEffect.getPdfCall')
                // getPdf();
                //  setTimeout(() => {
                //      navigate("/done");
                //      document.body.classList.remove("no-scroll");
                //  }, 8000);
            }
        }, 6000);
    }, [logo, bg, bloggers]);

    return (
        <>

            <div className="download" id={"download"}
                // style={{ width: '1080px' }}
            >


                <div className="scooter" style={bg}>
                    <div className="scooter_block">
                        <img
                            style={{
                                objectFit: "cover",
                                height: "180px",
                                width: '180px'
                            }}
                            src={logo}
                            alt={`Логотип бренда ${technicalTask.brand}`}
                        />

                        <p style={{color: "black"}}>{technicalTask.brand}</p>
                        <a target="_blank" href="https://bestbloggers.ru/" className="scooter_icon">
                            <img src={scooterIcon} alt="logo"/>
                            <p>BestBloggers.ru</p>
                        </a>
                    </div>


                </div>
                <div className="task" style={bg}>
                    <div className="task_icon">
                        <img
                            className={"top-logo"}
                            src={logo}
                            alt={`Логотип бренда ${technicalTask.brand}`}
                        />
                        <p>{technicalTask.brand}</p>
                    </div>
                    <div className="task_block">
                        <h2>Задача рекламной кампании</h2>
                        <p style={{textAlign: "center"}}>{technicalTask.task}</p>
                    </div>
                </div>
                {creatives?.map((creative, index) => (
                    <div className="task" style={bg}>
                        <div className="concept_icon">
                            <img
                                className={"top-logo"}
                                src={logo}
                                alt={`Логотип бренда ${technicalTask.brand}`}
                            />
                            <p>{technicalTask.brand}</p>
                        </div>
                        <div className="concept_block">
                            <h2>Концепция {index+1}</h2>
                            <p style={{textAlign: "center"}}>{creative.text}</p>
                        </div>
                    </div>
                ))}


                {
                    blogInfo.map(blog => {
                        console.log(blog);

                        return (
                            <>
                                <div className="task" style={bg} key={blog?.bloger?.id}>
                                    <div className="post_icon">
                                        <img
                                            className={"top-logo"}
                                            src={logo}
                                            alt={`Логотип бренда ${technicalTask.brand}`}
                                        />
                                        <p>{technicalTask.brand}</p>
                                    </div>
                                    {isInstagramBloggers() ? (
                                        <div className="instagram">
                                            <img src={instagramIcon} alt="instagramIcon"/>
                                            <h2>Instagram</h2>
                                        </div>
                                    ) : null}
                                    <div className="blogger-avoid">
                                        <h2>Пост:</h2>
                                        <div className="content ">
                                            <img className="content_img" src={newPhoto.find(p => p.id == blog.bloger.id)?.photo} alt=""/>
                                            <div className="content_text">
                                                {
                                                    blog?.post?.map(post => (
                                                        <div>{post}</div>
                                                    ))
                                                }
                                                <div>{blog?.creatives}</div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="task" style={bg} key={blog?.bloger?.id}>
                                    <div className="post_icon">
                                        <img
                                            className={"top-logo"}
                                            src={logo}
                                            alt={`Логотип бренда ${technicalTask.brand}`}
                                        />
                                        <p>{technicalTask.brand}</p>
                                    </div>
                                    <div className="blogerPage ">
                                        <BlogerCard
                                            bloger={blog?.bloger}
                                            cities={cities}

                                        />
                                    </div>
                                </div>
                            </>
                        )
                    })

                }


                {/*<div className="post" style={bg}>*/}
                {/*    {getBloggerForPost() && <CandidatesCard {...getBloggerForPost()} />}*/}
                {/*    {console.log("CHECK BLOGGERS", getBloggerForPost())}*/}
                {/*</div>*/}

                {/*{platforms.map(({id, name}) =>*/}
                {/*    bloggers?.some(({ploshchadka_id}) => ploshchadka_id == id) ? (*/}
                {/*        <div className="candidates" style={bg}>*/}
                {/*            <div className="candidates_icon">*/}
                {/*                <img*/}
                {/*                    className={"top-logo"}*/}
                {/*                    src={logo}*/}
                {/*                    alt={`Логотип бренда ${technicalTask.brand}`}*/}
                {/*                />*/}
                {/*                <p>{technicalTask.brand}</p>*/}
                {/*            </div>*/}
                {/*            <h2>Кандидаты. {name}. Действующие лица</h2>*/}
                {/*            {bloggers*/}
                {/*                .filter((blogger) => blogger.ploshchadka_id == id)*/}
                {/*                .map((blogger, index) => (*/}
                {/*                    // <div class={index != 0 && (index % 4 == 0) ? "breakLine" : ''}>*/}
                {/*                    <div className="blogger-avoid">*/}
                {/*                        <CandidatesCard*/}
                {/*                            onLoad={() => setDownloadedAvatarsCount((p) => p + 1)}*/}
                {/*                            {...blogger}*/}
                {/*                            key={blogger.id}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*        </div>*/}
                {/*    ) : null*/}
                {/*)}*/}

            </div>

            {/*<div className="download_modal">*/}
            {/*    <div class="lds-ring download_modal_loader">*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*    </div>*/}
            {/*    <p className="download_modal_title">Скоро начнётся загрузка..</p>*/}
            {/*</div>*/}
        </>
    );
};

export default connect()(ViewResult);
