import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/img/logo.png";
import mailRoutesImage from "../assets/img/mail_routes.svg";
import mailStartImage from "../assets/img/mail_start.svg";
import telegramRoutesImage from "../assets/img/telegram_routes.svg";
import telegramStartImage from "../assets/img/telegram_start.svg";
import MyInput from "./UI/MyInput";
import MyButton from "./UI/MyButton";
import MyTextarea from "./UI/MyTextarea";
import gptApi from "../config";
import "../assets/scss/done.scss";

const FeedbackForm = ({onClose}) => {

    const [values, setValues] = useState({name: '', email: '', message: ''})
    const [filled, setFilled] = useState(true)
    

    function sendEmail(e) {
        e.preventDefault();

        console.log('check values', values.name)

        if (values.name.length === 0 || values.email.length === 0) {
            setFilled(false)
            return
        }

        gptApi
        .post(
            "/api-web/mail",
            JSON.stringify({ subject: 'Обратная связь', body: `Имя: ${values.name}\n Почта: ${values.email}\n Сообщение: ${values.message}\n` })
        )
        .then((response) => {
            console.log(response)
            onClose()
        });
        
    }

    useEffect(() => {
    })

    return (
        <>
            <div className={'form-feedback'}>
                <h1>Напишите нам</h1>
                <span>Мы будем очень рады вашему письму!</span>
                <button className={'close'} onClick={onClose}> Закрыть</button>
                <div className={'form-field-container'}>
                    <div className={'form-field'}><span className={'form-field-label'}><span className={'star'}>*</span>Имя</span><MyInput style={{maxWidth: '200px'}} name={'name'} value={values.name} onChange={(text) => {setValues(prev => {console.log(values); return {...prev, name: text}})}}/></div>
                    <div className={'form-field'}><span className={'form-field-label'}><span className={'star'}>*</span>Электронная почта</span><MyInput name={'email'} style={{maxWidth: '200px'}} value={values.email} onChange={(text) => {setValues(prev => {return {...prev, email: text}})}}/></div>
                    <div className={'form-field-textarea'}><span className={'form-field-label'}>Текст сообщения</span><MyTextarea name={'comment'} style={{maxWidth: '200px'}} value={values.message} onChange={(e) => {setValues(prev => {return {...prev, message: e.target.value}})}}/></div>
                    {
                        !filled &&
                        <span> Заполните обязательные поля </span>
                    }

                    <span><span className={'star'}>*</span> помечены обязательные поля</span>
                    <MyButton className={"button"} text={"Отправить"} onClick={sendEmail} />
                </div>
            </div>
        </>
    );
};

export default FeedbackForm;
