import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import chevronRight from "../assets/img/chevron-right.svg";
import "../assets/scss/choose_creative.scss";
import MyButton from "../components/UI/MyButton";
import gptApi from "../config";
import { setCreatives as setCreativesAction } from "../store/actions/appStateAction";
import MyDisclaimer from "../components/UI/MyDisclaimer";
import clock from "../assets/img/clock.svg";

const ChooseCreative = () => {
    const [creatives, setCreatives] = useState([]);
    const [loading, setLoading] = useState(true);

    const briefCompany = useSelector((state) => state.dataAppState.technicalTask.task);
    const campaignId = useSelector(
        (state) => state.dataAppState.technicalTask.id
    )|| localStorage.getItem('id');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [selectedCreatives, setSelectedCreatives] = useState([]);

    const handleItemClick = (data) => {
        if (
            selectedCreatives.every(({ conceptId }) => conceptId != data.conceptId)
        ) {
            setSelectedCreatives((prev) => [...prev, data]);
        } else {
            setSelectedCreatives((prev) =>
                prev.filter(({ conceptId }) => conceptId != data.conceptId)
            );
        }
    };

    useEffect(() => {
        // Fetching data
        (async function () {
            setLoading(true);
            for (let i = 0; i <= 5; i++) {
                console.log(briefCompany)
                gptApi
                    .post(
                        "/api-web/make-concept?id=" + campaignId,
                        JSON.stringify({ description: briefCompany })
                    )
                    .then((response) => {
                        const creative = response.data;

                        setCreatives((prev) => [...prev, creative]);

                        if (i == 5) setLoading(false);
                    });
            }
        })();
    }, []);

    useEffect(() => {
        gptApi.get("/api-web/company-blogers?id=" + campaignId).then((response) => {
            // mobile safari canvas limit is low
            const data = response.data.data?.slice(0, /webos|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()) ? 20 : 100);
            console.log(data);
        });
    }, []);
    const state = useSelector(
        (state) => state.dataAppState
    );
    useEffect(() => {
        console.log(state);
    }, [state]);
    return (
        <div className="choose_creative">
            <div className="choose_creative_block">
                <div className="choose_creative_block_choose">
                    <h2 style={{marginBottom: '10px'}}>Выберите подходящий концепт и перейдите к следующему этапу</h2>
                    <div className="timing">
                        <img src={clock} alt=""/>
                        <p>Это может занять несколько минут</p>
                    </div>
                    <p>
                        Если ни один концепт не подошел, внесите изменение в бриф для
                        нейросети и попробуйте снова сформировать концепцию.
                    </p>

                    <div className="choose_creative_block_items">
                        {/* Creatives */}
                        {creatives.map((item, index) => (
                            <div
                                className={`choose_creative_block_items_item 
                                ${selectedCreatives.some(
                                    (selected) => selected.conceptId === item.conceptId
                                )
                                    ? "selected"
                                    : ""
                                }`}
                                key={item.conceptId}
                                onClick={() => handleItemClick(item)}
                            >
                                <p>
                                    <div className={`textIndex
                                    ${selectedCreatives.some(
                                        (selected) => selected.conceptId === item.conceptId
                                    )
                                        ? "selectedTextIndex"
                                        : ""
                                    }
                                    `}>{index+1}</div>
                                    {item.text}
                                </p>
                            </div>
                        ))}

                        {/* Skeleton */}
                        {loading &&
                            6 - creatives.length > 0 &&
                            new Array(6 - creatives.length).fill(null).map((item, index) => (
                                <div
                                    className={`choose_creative_block_items_item choose_creative_block_items_item_skeleton`}
                                    key={index}
                                >
                                    <div class="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="choose_creative_block_buttons">
                    <span onClick={() => navigate("/select_options")}>
                        Внести изменения в ТЗ
                    </span>
                    <div>
                        <span onClick={() => navigate(-1)}>Назад</span>
                        <MyButton
                            text={"Продолжить"}
                            icon={chevronRight}
                            disabled={!selectedCreatives.length}
                            onClick={() => {
                                dispatch(setCreativesAction(selectedCreatives));
                                //navigate("/formulate_brief_product");

                                navigate("/formulate_brief_product");
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        appState: state.dataAppState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCreatives: (data) => dispatch(setCreativesAction(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCreative);
