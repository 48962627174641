import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import chevronRight from "../assets/img/chevron-right.svg";
import fileIcon from "../assets/img/file-icon.png";
import "../assets/scss/select_options.scss";
import MyButton from "../components/UI/MyButton";
import MyInput from "../components/UI/MyInput";
import MyInputBlurPlaceholder from "../components/UI/MyInputBlurPlaceholder";
import MySlider from "../components/UI/MySlider";
import gptApi from "../config";
import {
    setBriefCompany,
    setTechnicalTask,
} from "../store/actions/appStateAction";
import {fetchBookArea} from "../store/actions/bookAreaAction";
import {fetchBookCategory} from "../store/actions/bookCategoryAction";
import {fetchBookCity} from "../store/actions/bookCityAction";
import {debounce} from "../utils/debounce";
import Select from 'react-select'
import arrowDown from "../assets/img/arrowDown.svg";
import arrowUp from "../assets/img/arrowUp.svg";
import alertImg from "../assets/img/alertImg.svg";
import info from "../assets/img/info.svg"
import deleteRed from "../assets/img/deleteRed.svg"
import editBlue from "../assets/img/editBlue.svg"
import imgPatternBlue from "../assets/img/imgPatternBlue.svg"

const SelectOptions = () => {
    const location = useLocation();
    const data = location.state?.data;

    const navigate = useNavigate();
    useEffect(() => {
        console.log(data);
    }, [data]);
    // Data
    const [selectedGender, setSelectedGender] = useState(["Мужской"]);
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedItemAudience, setSelectedItemsAudience] = useState([]);
    const [bloggersCount, setBloggersCount] = useState(0);
    const [checkAllCats, setCheckAllCats] = useState(false);
    const [checkAllCities, setCheckAllCities] = useState(false);
    const [filteredBloggers, setFilteredBloggers] = useState([]);
    const [logo, setLogo] = useState(null);
    const [campaign, setCampaign] = useState(data|| "");
    const [city, setCity] = useState([]);
    const [brand, setBrand] = useState("");
    const [task, setTask] = useState("");
    const [budgetLow, setBudgetLow] = useState(0);
    const [budgetHigh, setBudgetHigh] = useState(10000000);
    const [bloggersQty, setBloggersQty] = useState(0);
    const [subsCoverageLow, setSubsCoverageLow] = useState(0);
    const [subsCoverageHigh, setSubsCoverageHigh] = useState(10000000);
    const [pubsCoverageLow, setPubsCoverageLow] = useState(0);
    const [pubsCoverageHigh, setPubsCoverageHigh] = useState(10000000);
    const [audienceAgeLow, setAudienceAgeLow] = useState(0);
    const [audienceAgeHigh, setAudienceAgeHigh] = useState(100);
    const [cpv, setCpv] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    // Behavioral
    const [isFocusedCity, setIsFocusedCity] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isFocusedAudience, setIsFocusedAudience] = useState(false);
    const [errors, setErrors] = useState([]);

    const [showSelectedCities, setShowSelectedCities] = useState(false);
    const [showSelectedItemAudience, setShowSelectedItemAudience] = useState(false);
    const categoryRef = useRef();
    const dataBookArea = useSelector((state) => state.bookAreaState.data);
    const dataBookCity = useSelector((state) => state.bookCityState.data);
    const dataBookCategory = useSelector((state) => state.bookCategoryState.data);
    const a = useSelector((state) => state.dataAppState.technicalTask);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        console.log(selectedCategory);
    }, [selectedCategory]);

    function generateParams(
        audienceAgeHigh, audienceAgeLow,
        backgroundColors,
        bloggersQty,
        brand,
        budgetHigh,
        campaign,
        city,
        dataBookCategory,
        dataBookCity,
        logo,
        selectedColorIndex,
        selectedGender,
        selectedItemAudience,
        selectedItems,
        subsCoverageHigh,
        task,
        pubsCoverageHigh
    ) {
        const params = {};

        function setIfExist(value, name) {
            if (typeof value == "object" && !Array.isArray(value)) {
                // Распаковываем {value}
                if (value && value?.value?.length != 0) {
                    if (!Number.isNaN(+value.value)) params[name] = +value.value;
                    else params[name] = value.value;
                }
            } else {
                if (value?.length != 0) {
                    if (!Number.isNaN(+value) && !Array.isArray(value))
                        params[name] = +value;
                    else if (value) params[name] = value;
                }
            }
        }

        setIfExist(campaign, "company_name");
        setIfExist(brand, "bred");
        setIfExist(task, "description");
        setIfExist(budgetHigh, "project_budget");
        setIfExist(subsCoverageHigh, "subscriber");
        setIfExist(pubsCoverageHigh, "view");
        setIfExist(logo, "logo");
        setIfExist(bloggersQty, "quantity");

        if (typeof selectedColorIndex == "number")
            setIfExist(backgroundColors[selectedColorIndex] + "", "phot");
        // console.log("phot+" + backgroundColors[selectedColorIndex]);
        if (selectedGender.length)
            setIfExist(
                selectedGender.length == 2 ? ["Смешанный"] : [selectedGender[0]],
                "sex"
            );
        // console.log(String(selectedItems) + String(dataBookArea));
        if (selectedItems.length)

            setIfExist(
                selectedItems.map(
                    (itemName) =>
                        dataBookArea?.find((dataArea) => dataArea.name === itemName).id
                ),
                "area"
            );

        if (selectedItemAudience)

            setIfExist(
                selectedItemAudience.map(
                    (categoryName) =>
                        dataBookCategory?.find((category) => category.name == categoryName)
                            .id
                ),
                "categories_id"
            );

        if (city)
            setIfExist(
                city.map(
                    (cityName) => dataBookCity?.find((city) => city.name == cityName)?.id
                ),
                "city_id"
            );

        function getAgeString(age) {
            let string;

            if (age < 18) string = "От 13 до 17";
            else if (age < 25) string = "От 18 до 24";
            else if (age < 35) string = "От 25 до 34";
            else if (age < 45) string = "От 35 до 44";
            else if (age < 55) string = "От 45 до 54";
            else if (age < 65) string = "От 55 до 64";
            else string = "От 65+";

            return string;
        }

        const ageArr = [];

        const ageConf = [{min: 13, max: 17, value: "От 13 до 17"},
            {min: 18, max: 24, value: "От 18 до 24"},
            {min: 25, max: 34, value: "От 25 до 34"},
            {min: 35, max: 44, value: "От 35 до 44"},
            {min: 45, max: 54, value: "От 45 до 54"},
            {min: 55, max: 64, value: "От 55 до 64"},
            {min: 65, max: 65, value: "От 65+"},]

        ageConf.forEach(({min, max, value}) => {
            if (audienceAgeLow <= min && max <= audienceAgeHigh) ageArr.push(value)
        })
        console.log(ageArr, audienceAgeLow <= 65 && audienceAgeHigh >= 100, audienceAgeLow, audienceAgeHigh)
        setIfExist(ageArr, "age");

        return params;
    }

    function saveTechnicalTask() {
        if (a.id) {
            // Edit
            dispatch(setBriefCompany(task));
            gptApi
                .post(
                    "/api-web/advertising-company-update?id=" + a.id,
                    JSON.stringify(
                        generateParams(
                            audienceAgeHigh, audienceAgeLow,
                            backgroundColors,
                            bloggersQty,
                            brand,
                            budgetHigh,
                            campaign,
                            city,
                            dataBookCategory,
                            dataBookCity,
                            logo,
                            selectedColorIndex,
                            selectedGender,
                            selectedItemAudience,
                            selectedItems,
                            subsCoverageHigh,
                            task,
                            pubsCoverageHigh
                        )
                    )
                )
                .then((response) => {
                    const res = response.data;
                    if (!res.id) {
                        // errors
                        setErrors(Object.values(res).flat());
                        return;
                    }
                    dispatch(setBriefCompany(task));
                    // console.log("save");
                    dispatch(
                        setTechnicalTask({
                            selectedColorIndex,
                            selectedGender,
                            selectedItemAudience,
                            selectedItems,
                            bloggersQty,
                            budgetHigh,
                            budgetLow,
                            backgroundColors,
                            campaign,
                            logo,
                            cpv,
                            audienceAgeHigh,
                            audienceAgeLow,
                            pubsCoverageHigh,
                            pubsCoverageLow,
                            subsCoverageHigh,
                            subsCoverageLow,
                            brand,
                            task,
                            city,
                            id: res.id,
                        })
                    );
                    navigate("/choose_blogers");
                });
        } else {
            // Create

            gptApi
                .post(
                    "/api-web/advertising-company-create",
                    JSON.stringify(
                        generateParams(
                            audienceAgeHigh, audienceAgeLow,
                            backgroundColors,
                            bloggersQty,
                            brand,
                            budgetHigh,
                            campaign,
                            city,
                            dataBookCategory,
                            dataBookCity,
                            logo,
                            selectedColorIndex,
                            selectedGender,
                            selectedItemAudience,
                            selectedItems,
                            subsCoverageHigh,
                            task,
                            pubsCoverageHigh
                        )
                    )
                )
                .then((response) => {
                    const res = response.data;
                    if (!res.id) {
                        // errors
                        setErrors(Object.values(res).flat());
                        return;
                    }
                    localStorage.setItem('id', res.id);
                    // console.log("save");
                    dispatch(
                        setTechnicalTask({
                            selectedColorIndex,
                            selectedGender,
                            selectedItemAudience,
                            selectedItems,
                            bloggersQty,
                            budgetHigh,
                            budgetLow,
                            backgroundColors,
                            campaign,
                            logo,
                            cpv,
                            audienceAgeHigh,
                            audienceAgeLow,
                            pubsCoverageHigh,
                            pubsCoverageLow,
                            subsCoverageHigh,
                            subsCoverageLow,
                            brand,
                            task,
                            city,
                            id: res.id,
                        })
                    );
                    navigate("/choose_blogers");
                });
        }
    }

    useEffect(() => {

        if (!a.city) return;
        console.log(a);

        setCity(a.city);
        setSelectedColorIndex(a.selectedColorIndex);
        setSelectedGender(a.selectedGender);
        setSelectedItemsAudience(a.selectedItemAudience);
        setSelectedItems(a.selectedItems);
        setBloggersQty(a.bloggersQty);
        setBudgetHigh(a.budgetHigh);
        setBudgetLow(a.budgetLow);
        setCampaign(a.campaign);
        setLogo(a.logo);
        setCpv(a.cpv);
        setAudienceAgeHigh(a.audienceAgeHigh);
        setAudienceAgeLow(a.audienceAgeLow);
        setPubsCoverageHigh(a.pubsCoverageHigh);
        setPubsCoverageLow(a.pubsCoverageLow);
        setSubsCoverageHigh(a.subsCoverageHigh);
        setSubsCoverageLow(a.subsCoverageLow);
        setTask(a.task);
        setBrand(a.brand);
        setBackgroundColors(a.backgroundColors);
    }, []);

    const dispatch = useDispatch();

    const sliderRef = useRef(null);
    const [backgroundColors, setBackgroundColors] = useState([
        "linear-gradient(to right top, #091E3A 0%, #2F80ED 100%, #2D9EE0 100%)",
        "linear-gradient(to right top, #C84E89 0%, #F15F79 100%)",
        "linear-gradient(to right top, #FD746C 0%, #FF9068 100%)",
        "linear-gradient(to right top, #5433FF 0%, #20BDFF 100%, #A5FECB 100%)",
        "linear-gradient(to right top, #9796F0 0%, #FBC7D4 100%)",
        "linear-gradient(to right top, #E65C00 0%, #F9D423 100%)",
        "linear-gradient(to right top, #E52D27 0%, #B31217 100%)",
        "linear-gradient(to right top, #DA22FF 0%, #9733EE 100%)",
        "linear-gradient(to right top, #F09819 0%, #EDDE5D 100%)",
        "#131313",
        "#ffffff",
    ]);

    useEffect(() => {
        dispatch(fetchBookArea());
        dispatch(fetchBookCity());
        dispatch(fetchBookCategory());
    }, [dispatch]);
    const cities = useSelector(
        (state) => state
    );

    useEffect(() => {
        console.log(cities);
    }, [cities]);
    const debouncedQuery = useCallback(
        debounce(
            (
                audienceAgeHigh, audienceAgeLow,
                backgroundColors,
                bloggersQty,
                brand,
                budgetHigh,
                campaign,
                city,
                dataBookCategory,
                dataBookCity,
                logo,
                selectedColorIndex,
                selectedGender,
                selectedItemAudience,
                selectedItems,
                subsCoverageHigh,
                task,
                pubsCoverageHigh
            ) => {
                const params = generateParams(
                    audienceAgeHigh, audienceAgeLow,
                    backgroundColors,
                    bloggersQty,
                    brand,
                    budgetHigh,
                    campaign,
                    city,
                    dataBookCategory,
                    dataBookCity,
                    logo,
                    selectedColorIndex,
                    selectedGender,
                    selectedItemAudience,
                    selectedItems,
                    subsCoverageHigh,
                    task,
                    pubsCoverageHigh
                );

                // setIfExist(, "view");
                console.log(params);
                // Dynamic fetching bloggers
                gptApi
                    .post("/api-web/blogers-count", JSON.stringify(params))
                    .then((response) => {
                        const count = response.data.count;


                        setBloggersCount(count);
                    });

            },
            500
        ),
        [dataBookArea, dataBookCategory, dataBookCity]
    );

    useEffect(
        () =>
            debouncedQuery(
                audienceAgeHigh,
                audienceAgeLow,
                backgroundColors,
                bloggersQty,
                brand,
                budgetHigh,
                campaign,
                city,
                dataBookCategory,
                dataBookCity,
                logo,
                selectedColorIndex,
                selectedGender,
                selectedItemAudience,
                selectedItems,
                subsCoverageHigh,
                task,
                pubsCoverageHigh
            ),
        [
            audienceAgeHigh, audienceAgeLow,
            backgroundColors,
            bloggersQty,
            brand,
            budgetHigh,
            campaign,
            city,
            dataBookCategory,
            dataBookCity,
            logo,
            selectedColorIndex,
            selectedGender,
            selectedItemAudience,
            selectedItems,
            subsCoverageHigh,
            task,
            pubsCoverageHigh,
        ]
    );
    console.log(dataBookCategory);
    const handleSelectFocus = () => {
        setIsFocused((prevIsFocused) => !prevIsFocused);
    };

    const handleSelectFocusAudience = () => {
        setIsFocusedAudience((prevIsFocused) => !prevIsFocused);
    };

    const handleSelectFocusCity = () => {
        setIsFocusedCity((prevIsFocused) => !prevIsFocused);
    };

    const handleColorClick = (index) => {
        setSelectedColorIndex(index);
    };

    const handleOptionSelect = (data) => {
        const selectedValue = data;

        if (!selectedItems.includes(selectedValue)) {
            setSelectedItems((prevSelectedItems) => [
                ...prevSelectedItems,
                selectedValue,
            ]);
        } else
            handleRemoveItem(data)
    };
    const handleCategorySelect = (data) => {
        const selectedValue = data;
        if (selectedValue === "all" && selectedItemAudience.length === dataBookCategory.length) {
            setSelectedItemsAudience([]);
            return;
        }
        if (selectedValue === "all") {
            setSelectedItemsAudience(dataBookCategory?.map((c) => c.name));
            return;
        }


        if (!selectedItemAudience.includes(selectedValue)) {
            setSelectedItemsAudience((prevSelectedCategory) => [
                ...prevSelectedCategory,
                selectedValue,
            ]);
        } else
            setSelectedItemsAudience((prevSelectedCategory) =>
                prevSelectedCategory.filter((item) => item !== data)
            );
    };
    useEffect(() => {

        console.log(city);

    }, [city]);
    const handleOptionSelectAudience = (event) => {
        setCheckAllCats(false);
        const selectedValue = event.name;


        console.log(selectedItemAudience);
        if (selectedValue == "Выбрать все тематики") {
            setSelectedItemsAudience(dataBookCategory?.map((c) => c.name));
            setCheckAllCats(true);
            return;
        }

        if (!selectedItemAudience.includes(selectedValue)) {
            setSelectedItemsAudience((prevSelectedItems) => [
                ...prevSelectedItems,
                selectedValue,
            ]);
        }
    };

    const handleRemoveItem = (data) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.filter((item) => item !== data)
        );
    };

    const handleOptionSelectCity = (event) => {
        console.log("CHECK CITY", city)

        setCheckAllCities(false);
        const selectedValue = event.value;
        if (selectedValue == "all") {
            setCity(dataBookCity?.map((c) => c.name));
            setCheckAllCities(true);
            return;
        }
        if (!city.includes(selectedValue)) {
            setCity((prevSelectedItems) => [...prevSelectedItems, selectedValue]);
        }
    };

    const handleRemoveItemCity = (index) => {
        setCity((prevSelectedItems) =>
            prevSelectedItems.filter((item, i) => i !== index)
        );
    };

    const handleRemoveItemAudience = (index) => {
        setSelectedItemsAudience((prevSelectedItems) =>
            prevSelectedItems.filter((item, i) => i !== index)
        );
    };

    const handleSelectBlur = () => {
        setIsFocused(false);
        setIsFocusedAudience(false);
        setIsFocusedCity(false);
    };

    const handleSelectLogo = async (event) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.name = "uploadPhotoInput";
        input.style.display = 'none';
        document.body.append(input)

        input.addEventListener('change', async function (e) {
            const files = await e.target.files;

            const formData = new FormData();
            formData.set("file", files[0]);

            console.log(files[0])
            gptApi.post("/api-web/upload-file", formData).then((response) => {
                const url = response.data.url;

                setLogo(url);
            });
        })

        input.click();
    };

    const handleSelectBackground = async (event) => {
        // console.log("h");
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.style.display = 'none';
        document.body.append(input)


        input.addEventListener('change', async function (e) {
            const files = await e.target.files;

            const formData = new FormData();
            formData.set("file", files[0]);

            gptApi.post("/api-web/upload-file", formData).then((response) => {
                const url = response.data.url;

                setBackgroundColors((prev) => [...prev, url]);
            });
        });

        input.click();
    };

    const toggleGender = (gender) => {
        if (selectedGender.includes(gender))
            setSelectedGender((prev) => prev.filter((g) => g != gender));
        else setSelectedGender((prev) => [...prev, gender]);
    };

    const nextButtonDisabled = useMemo(() => {
        const allRequiredFieldsFilled =
            audienceAgeHigh &&
            bloggersQty &&
            brand &&
            budgetHigh &&
            campaign &&
            logo &&
            selectedItemAudience &&
            selectedItemAudience.length &&
            selectedColorIndex != null &&
            selectedColorIndex != undefined &&
            selectedGender &&
            selectedItems &&
            subsCoverageHigh &&
            task &&
            pubsCoverageHigh;
        console.log(allRequiredFieldsFilled);

        return bloggersCount == 0 || !allRequiredFieldsFilled;
    }, [
        bloggersCount,
        audienceAgeHigh, audienceAgeLow,
        bloggersQty,
        brand,
        budgetHigh,
        campaign,
        city,
        logo,
        selectedColorIndex,
        selectedGender,
        selectedItemAudience,
        selectedItems,
        subsCoverageHigh,
        task,
        cpv,
        pubsCoverageHigh,
    ]);
    useEffect(() => {
        console.log(selectedItems);
    }, [selectedItems]);
    const [openControlLogo, setOpenControlLogo] = useState(false);
    return (
        <div className="select_options">
            <div className="select_options_block ">
                <div className="select_options_block_options ">
                    <div className="borderBottom">
                        <h2 className="select_options_title">
                            Выберите параметры вашей рекламной кампании
                        </h2>
                        <div className="select_options_block_options_file-block">
                            {logo ? (
                                <div className="file" onClick={() => {
                                    setOpenControlLogo(!openControlLogo);
                                }}>
                                    <img
                                        style={{objectFit: "contain"}}
                                        src={process.env.REACT_APP_BASE_URL + "/" + logo}
                                        alt="Logo"
                                        className="select_options_logo"
                                    />
                                </div>
                            ) : (
                                <div className="file" onClick={() => {
                                    setOpenControlLogo(!openControlLogo);
                                }}>
                                    <img src={fileIcon} alt="File Icon" className="file_icon"/>
                                    <input
                                        type="file"
                                        className="file_input"
                                        onClick={(e) => e.preventDefault()}
                                    />
                                    <span className="file_text">Выберите логотип*</span>
                                </div>
                            )}
                            {
                                openControlLogo &&
                                <div className="select_options_block_options_controlFile">
                                    <div className="controlFileItemTop"
                                         onClick={() => {
                                             setOpenControlLogo(false);
                                             handleSelectLogo();
                                         }}
                                    ><img src={imgPatternBlue} alt=""/>Открыть изображение
                                    </div>
                                    <div className="controlFileItem"
                                         onClick={() => {
                                             setOpenControlLogo(false);
                                             handleSelectLogo();
                                         }}
                                    ><img src={editBlue} alt=""/>Обновить изображение
                                    </div>
                                    <div className="controlFileItemBot"
                                         onClick={() => {
                                             setOpenControlLogo(false);
                                             setLogo(null);
                                         }}
                                    ><img src={deleteRed} alt=""/>Удалить изображение
                                    </div>
                                </div>
                            }
                            <div className="inputs">
                                <MyInputBlurPlaceholder
                                    inputValue={campaign}
                                    defaultValue={campaign}
                                    setInputValue={setCampaign}
                                    placeholder={"Название кампании*"}
                                    style={{width: "100%"}}

                                />
                                <MyInputBlurPlaceholder
                                    inputValue={brand}
                                    setInputValue={setBrand}
                                    placeholder={"Бренд*"}
                                    style={{width: "100%", marginTop: "20px"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="select_options_block_background">*/}
                {/*    <h2 className="title">Выбрать фон</h2>*/}
                {/*    <div className="carousel-wrapper">*/}
                {/*        <Slider*/}
                {/*            ref={sliderRef}*/}
                {/*            dots={false}*/}
                {/*            // slidesToShow={2}*/}
                {/*            slidesToScroll={1}*/}
                {/*            infinite={false}*/}
                {/*            responsive={[*/}
                {/*                {*/}
                {/*                    settings: {*/}
                {/*                        slidesToShow: 3,*/}
                {/*                        slidesToScroll: 3,*/}
                {/*                    },*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            {[...backgroundColors, 'newSlot'].map((color, index) => (*/}
                {/*                <React.Fragment key={index}>*/}
                {/*                    {color == 'newSlot' ? <div className={`file ${selectedColorIndex === index ? "selectedColor" : ""*/}
                {/*                        }`} onClick={handleSelectBackground}>*/}
                {/*                        <img src={fileIcon} alt="File Icon" className="file_icon" />*/}
                {/*                        <input*/}
                {/*                            type="file"*/}
                {/*                            onClick={(e) => e.preventDefault()}*/}
                {/*                            className="file_input"*/}
                {/*                        />*/}
                {/*                        <span className="file_text">Выберите файл</span>*/}
                {/*                    </div> : color.includes("uploads") ? (*/}
                {/*                        <div*/}
                {/*                            className={`background_item ${selectedColorIndex === index ? "selectedColor" : ""*/}
                {/*                                }`}*/}
                {/*                            onClick={() => handleColorClick(index)}*/}
                {/*                        >*/}
                {/*                            <img style={{ objectFit: "contain" }}*/}
                {/*                                className="background_item_image" src={process.env.REACT_APP_BASE_URL + "/" + color} />*/}
                {/*                        </div>*/}
                {/*                    ) : (*/}
                {/*                        <div*/}
                {/*                            className={`background_item ${selectedColorIndex === index ? "selectedColor" : ""*/}
                {/*                                }`}*/}
                {/*                            onClick={() => handleColorClick(index)}*/}
                {/*                            style={{ background: color }}*/}
                {/*                        ></div>*/}
                {/*                    )}*/}


                {/*                </React.Fragment>*/}
                {/*            ))}*/}
                {/*        </Slider>*/}
                {/*    </div>*/}
                {/*    <div className="background_block">*/}
                {/*        {backgroundColors.map((color, index) => (*/}
                {/*            <React.Fragment key={index}>*/}
                {/*                {color.includes("upload") ? (*/}
                {/*                    <div*/}
                {/*                        className={`background_item ${selectedColorIndex === index ? "selectedColor" : ""*/}
                {/*                            }`}*/}
                {/*                        onClick={() => handleColorClick(index)}*/}
                {/*                        style={{ background: color }}*/}
                {/*                    >*/}
                {/*                        <img*/}
                {/*                            style={{ objectFit: "contain" }}*/}
                {/*                            className="background_item_image"*/}
                {/*                            src={process.env.REACT_APP_BASE_URL + "/" + color}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                ) : (*/}
                {/*                    <div*/}
                {/*                        className={`background_item ${selectedColorIndex === index ? "selectedColor" : ""*/}
                {/*                            }`}*/}
                {/*                        onClick={() => handleColorClick(index)}*/}
                {/*                        style={{ background: color }}*/}
                {/*                    ></div>*/}
                {/*                )}*/}
                {/*                {index === backgroundColors.length - 1 && (*/}
                {/*                    <div className="file" onClick={handleSelectBackground}>*/}
                {/*                        <img src={fileIcon} alt="File Icon" className="file_icon" />*/}
                {/*                        <input*/}
                {/*                            type="file"*/}
                {/*                            onClick={(e) => e.preventDefault()}*/}
                {/*                            className="file_input"*/}
                {/*                        />*/}
                {/*                        <span className="file_text">Выберите файл</span>*/}
                {/*                    </div>*/}
                {/*                )}*/}
                {/*            </React.Fragment>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="select_options_block_site">
                    <div className="borderBottom">
                        <h2 className="title">Выбрать площадку*</h2>
                        <div className="audience_block">
                            <div className="platform_content">
                                {
                                    dataBookArea &&
                                    dataBookArea.map(item => (
                                        <span
                                            key={item.id}
                                            className={`platform ${selectedItems.includes(item.name) ? "selected_platform" : ""
                                            }`}
                                            onClick={() => handleOptionSelect(item.name)}
                                        >
                                            {item.name}
                                        </span>
                                    ))

                                }
                            </div>
                        </div>
                    </div>

                    {/*<select*/}
                    {/*    className={`select ${isFocused ? "focused" : ""}`}*/}
                    {/*    onClick={handleSelectFocus}*/}
                    {/*    onChange={handleOptionSelect}*/}
                    {/*    onBlur={handleSelectBlur}*/}
                    {/*>*/}
                    {/*    <option value="" disabled selected style={{color: "#0000004D"}}>*/}
                    {/*        Выбрать*/}
                    {/*    </option>*/}
                    {/*    {dataBookArea &&*/}
                    {/*        dataBookArea.map((item) => (*/}
                    {/*            <option key={item.id} value={item.name}>*/}
                    {/*                {item.name}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*</select>*/}
                    {/*<div className="selected_items">*/}
                    {/*    {selectedItems.map((selectedItem, index) => (*/}
                    {/*        <div className="selected_item" key={index}>*/}
                    {/*            <span>{selectedItem}</span>*/}
                    {/*            <img*/}
                    {/*                src={require("../assets/img/x.png")}*/}
                    {/*                alt="x"*/}
                    {/*                onClick={() => handleRemoveItem(index)}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </div>
                <div className="select_options_block_task borderBottom">
                    <h2 className="title">Задача*</h2>
                    <p>
                        {" "}
                        <a
                            href="https://vc.ru/chatgpt/594829-kak-sostavit-idealnyy-zapros-k-chatgpt-poshagovoe-rukovodstvo-primery?ysclid=lkhfp7asek996436416."
                            style={{textDecoration: "underline"}}
                            target="_blank"
                        >
                            Перед заполнением можете ознакомиться с гайдом по составлению
                            задачи для Нейросети
                        </a>
                    </p>
                    <textarea
                        placeholder={"Опишите задачу рекламной кампании 1-2 предложением"}
                        value={task}
                        onChange={(e) => setTask(e.target.value)}
                    />
                </div>
                <div className="select_options_block_category">
                    <div className="ctegoryBlock">
                        <h2>Критерии отбора блогера</h2>
                        <div className="project_budget">
                            <p>Бюджет проекта*</p>
                            <div className="project_budget_block">
                                <MyInput
                                    value={budgetLow.value || budgetLow}
                                    onChange={setBudgetLow}
                                    children={"От"}
                                    placeholder={"0 ₽"}
                                    style={{
                                        paddingLeft: "15px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                        width: "100%",
                                        height: "48px",
                                    }}
                                />
                                <img src={require("../assets/img/solid.png")} alt="-"/>
                                <MyInput
                                    value={budgetHigh.value || budgetHigh}
                                    onChange={setBudgetHigh}
                                    children={"До"}
                                    placeholder={"0 ₽"}
                                    style={{
                                        paddingLeft: "15px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                        width: "100%",
                                        height: "48px",
                                    }}
                                />
                            </div>
                            <MySlider
                                style={{marginBottom: "20px"}}
                                min={0}
                                max={100000000}
                                value={[budgetLow.value || budgetLow, budgetHigh.value || budgetHigh]}
                                minDistance={100}
                                onChange={(v) => {
                                    setBudgetLow(v[0]);
                                    setBudgetHigh(v[1]);
                                }}
                            />
                            <MyInputBlurPlaceholder
                                inputValue={bloggersQty == 0 ? "" : bloggersQty}
                                setInputValue={setBloggersQty}
                                type={'number'}
                                children={"Количество блогеров для проекта"}
                                placeholder={"Количество блогеров для проекта*"}
                                style={{width: "100%"}}
                            />
                        </div>
                        <div className="subscribers">
                            <p>Суммарный охват в подписчиках*</p>
                            <div className="subscribers_block">
                                <MyInput
                                    value={subsCoverageLow.value || subsCoverageLow}
                                    onChange={setSubsCoverageLow}
                                    children={"От"}
                                    placeholder={"0"}
                                    style={{
                                        paddingLeft: "15px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                        width: "100%",
                                        height: "48px",
                                    }}
                                />
                                <img src={require("../assets/img/solid.png")} alt="-"/>
                                <MyInput
                                    value={subsCoverageHigh.value || subsCoverageHigh}
                                    onChange={setSubsCoverageHigh}
                                    children={"До"}
                                    placeholder={"0"}
                                    style={{
                                        paddingLeft: "15px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                        width: "100%",
                                        height: "48px",
                                    }}
                                />
                            </div>
                            <MySlider
                                style={{marginBottom: "20px"}}
                                min={0}
                                max={10000000}
                                value={[subsCoverageLow.value || subsCoverageLow, subsCoverageHigh.value || subsCoverageHigh]}
                                minDistance={100}
                                onChange={(v) => {
                                    setSubsCoverageLow(v[0]);
                                    setSubsCoverageHigh(v[1]);
                                }}
                            />
                        </div>
                        <div className="publications">
                            <p>Суммарный охват публикаций*</p>
                            <div className="publications_block">
                                <MyInput
                                    value={pubsCoverageLow.value || pubsCoverageLow}
                                    onChange={setPubsCoverageLow}
                                    children={"От"}
                                    placeholder={"0"}
                                    style={{
                                        paddingLeft: "15px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                        width: "100%",
                                        height: "48px",
                                    }}
                                />
                                <img src={require("../assets/img/solid.png")} alt="-"/>
                                <MyInput
                                    value={pubsCoverageHigh.value || pubsCoverageHigh}
                                    onChange={setPubsCoverageHigh}
                                    children={"До"}
                                    placeholder={"0"}
                                    style={{
                                        paddingLeft: "15px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                        width: "100%",
                                        height: "48px",
                                    }}
                                />
                            </div>

                            <MySlider
                                style={{marginBottom: "20px"}}
                                min={0}
                                max={10000000}
                                value={[pubsCoverageLow.value || pubsCoverageLow, pubsCoverageHigh.value || pubsCoverageHigh]}
                                minDistance={100}
                                onChange={(v) => {
                                    setPubsCoverageLow(v[0]);
                                    setPubsCoverageHigh(v[1]);
                                }}
                            />
                        </div>
                        <div className="audience">
                            {/*<p>Аудитория проекта</p>*/}
                            {/*<div className="audience_block">*/}
                            {/*<span*/}
                            {/*    className={`man ${selectedGender.includes("Мужской") ? "selected" : ""*/}
                            {/*    }`}*/}
                            {/*    onClick={() => toggleGender("Мужской")}*/}
                            {/*>*/}
                            {/*    Мужчины*/}
                            {/*</span>*/}
                            {/*    <span*/}
                            {/*        className={`woman ${selectedGender.includes("Женский") ? "selected" : ""*/}
                            {/*        }`}*/}
                            {/*        onClick={() => toggleGender("Женский")}*/}
                            {/*    >*/}
                            {/*    Женщины*/}
                            {/*</span>*/}
                            {/*</div>*/}
                            <h2 className="title">Тематика проекта*</h2>

                            <Select
                                options={dataBookCategory && [
                                    {
                                        value: "all",
                                        name: "Выбрать все тематики"
                                    },
                                    ...dataBookCategory.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
                                ]}
                                closeMenuOnSelect={false}
                                onClick={handleSelectFocusAudience}
                                onChange={handleOptionSelectAudience}
                                onBlur={handleSelectBlur}
                                value={selectedItemAudience[selectedItemAudience.length]}
                                isSearchable={false}
                                ref={categoryRef}
                                placeholder={"Все тематики"}
                                getOptionLabel={(option) => (
                                    <div className="oprionSelect"
                                         style={{backgroundColor: option.value === "reset" ? '#f8f8f8' : ''}}
                                    >
                                        <input className="oprionSelectCheck" type="checkbox"
                                               onChange={() => {

                                                   if (option.value === "all") {
                                                       setSelectedItemsAudience(!checkAllCats ? dataBookCategory?.map((c) => c.name) : [])
                                                       setCheckAllCats(!checkAllCats);
                                                   }
                                                   console.log(option);
                                                   console.log(selectedItemAudience);
                                                   console.log(selectedItemAudience.includes(option.name));

                                               }}
                                            // style={{ display: option.value === "all" ? 'none' : 'block' }}
                                               checked={selectedItemAudience.includes(option.name) || (option.value === "all" && checkAllCats)}/>
                                        <span>{option.name}</span>
                                    </div>
                                )}

                                unstyled={true}
                                classNames={{
                                    control: (state) =>
                                        `${isFocusedCity ? "focus" : ""}`,

                                }}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        border: state.isFocused ? '1px solid #ff774d' : '1px solid #d8d6de',
                                        width: "100%",
                                        padding: "15px",
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        lineHeight: "19px",
                                        backgroundColor: "#f9f9f9",
                                        borderRadius: "8px",
                                        outline: "none",
                                        transition: "box-shadow 0.2s ease-in-out",
                                        backgroundImage: 'url("../img/down.svg")',
                                        backgroundPosition: "right 15px center",
                                        backgroundRepeat: "no-repeat",
                                        '&:hover': {
                                            border: '1px solid #ff774d' // Красная граница при наведении
                                        }

                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        marginTop: "10px",
                                        paddingBottom: "10px",
                                        height: "200px",
                                        borderRadius: "10px",
                                        backgroundColor: "white",
                                        zIndex: 10000,
                                    }),
                                    dropdownIndicator: (baseStyles, state) => ({
                                        ...baseStyles,
                                        display: "none"
                                    }),
                                    option: (baseStyles, {isSelected, isFocused, data}) => ({
                                        ...baseStyles,
                                        background: isFocused ? "#eaf0fe" : "none",
                                        padding: "8px 14px",
                                        display: 'flex',
                                        backgroundColor: data.value === "reset" ? '#f8f8f8' : "",
                                        alignItems: 'center',
                                        border: '1px solid #d8d6de'
                                    }),
                                }}
                                classNames={{
                                    control: (state) =>
                                        `select2 ${isFocusedCity ? "focused" : ""}`,
                                }}

                            />
                            <div className="showBlogers">
                                <div className="showBlogersBtn"
                                     onClick={() => setShowSelectedItemAudience((show) => !show)}>
                                     <span className={`woman selected`}>
                                            {!showSelectedItemAudience
                                                ? "Показать выбранные тематики"
                                                : "Скрыть  выбранные тематики"}
                                        </span>
                                    <img src={!showSelectedItemAudience ? arrowDown : arrowUp} alt=""/>
                                </div>

                                <div className="showBlogersClear" onClick={() => {
                                    setSelectedItemsAudience([])

                                }}>Очистить все
                                </div>
                            </div>

                            <div className="selected_items">


                                {showSelectedItemAudience && selectedItemAudience.map((selectedItem, index) => (
                                    <div className="selected_item" key={index}>
                                        <span>{selectedItem}</span>
                                        <img
                                            src={require("../assets/img/x.png")}
                                            alt="x"
                                            onClick={() => handleRemoveItemAudience(index)}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* <MySelectBlurPlaceholder
              selectedOption={city}
              setSelectedOption={setCity}
              children={"Город"}
              placeholder={"Город"}
              style={{ width: "100%" }}
              options={dataBookCity ? dataBookCity : []}
            /> */}
                            <Select
                                unstyled={true}
                                onClick={handleSelectFocusCity}
                                onChange={handleOptionSelectCity}
                                onBlur={handleSelectBlur}
                                closeMenuOnSelect={false}
                                placeholder={"Город"}
                                options={[{
                                    value: "all",
                                    label: "Выбрать все города"
                                }, ...dataBookCity?.sort(function (a, b) {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }).map((item) => {
                                    return {value: item.name, label: item.name}
                                }) || []]}
                                getOptionLabel={(option) => (
                                    <div className="oprionSelect">
                                        <input className="oprionSelectCheck" type="checkbox"
                                               onChange={() => {


                                                   if (option.value === "all") {
                                                       console.log(dataBookCategory?.map((c) => c.name));
                                                       setCity(!checkAllCities ? dataBookCategory?.map((c) => c.name) : [])
                                                       setCheckAllCities(!checkAllCities);
                                                   }
                                                   console.log(option);
                                                   console.log(city);
                                                   console.log(city.includes(option.label));

                                               }}
                                               // checked={(checkAllCities ? true : city.includes(option.value)) || (option.value === "all" && checkAllCities)}/>
                                                checked={city.includes(option.label) || (option.value === "all" && checkAllCities)}/>
                                        <span>{option.label}</span>
                                    </div>
                                )}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        border: state.isFocused ? '1px solid #ff774d' : '1px solid #d8d6de',
                                        width: "100%",
                                        padding: "15px",
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        lineHeight: "19px",
                                        backgroundColor: "#f9f9f9",
                                        borderRadius: "8px",
                                        outline: "none",
                                        transition: "box-shadow 0.2s ease-in-out",
                                        backgroundImage: 'url("../img/down.svg")',
                                        backgroundPosition: "right 15px center",
                                        backgroundRepeat: "no-repeat",
                                        '&:hover': {
                                            border: '1px solid #ff774d' // Красная граница при наведении
                                        }

                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        marginTop: "10px",
                                        paddingBottom: "10px",
                                        height: "200px",
                                        borderRadius: "10px",
                                        backgroundColor: "white",
                                        zIndex: 10000,
                                    }),
                                    dropdownIndicator: (baseStyles, state) => ({
                                        ...baseStyles,
                                        display: "none"
                                    }),
                                    option: (baseStyles, {isSelected, isFocused}) => ({
                                        ...baseStyles,
                                        background: isFocused ? "#eaf0fe" : "none",
                                        padding: "8px 14px",
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '1px solid #d8d6de'
                                    }),
                                }}

                                classNames={{
                                    control: (state) =>
                                        `select ${isFocusedCity ? "focused" : ""}`,
                                }}

                            />
                            <div className="audience">

                                <div className="showBlogers"

                                >
                                    <div className="showBlogersBtn"
                                         onClick={() => setShowSelectedCities((show) => !show)}>
                                     <span className={`woman selected`}>
                                             {!showSelectedCities
                                                 ? "Показать выбранные города"
                                                 : "Скрыть  выбранные города"}
                                        </span>
                                        <img src={!showSelectedCities ? arrowDown : arrowUp} alt=""/>
                                    </div>

                                    <div className="showBlogersClear" onClick={() => setCity([])}>
                                        Очистить все
                                    </div>
                                </div>
                            </div>
                            {showSelectedCities ? (
                                <div className="selected_items">
                                    {city.map((selectedItem, index) => (
                                        <div className="selected_item" key={index}>
                                            <span>{selectedItem}</span>
                                            <img
                                                src={require("../assets/img/x.png")}
                                                alt="x"
                                                onClick={() => handleRemoveItemCity(index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                        {/*<div className="bloger">*/}
                        {/*    <p>Преимущественный возраст аудитории блогера*</p>*/}
                        {/*    <div className="bloger_block">*/}
                        {/*        <MyInput*/}
                        {/*            value={audienceAgeLow.value || audienceAgeLow}*/}
                        {/*            onChange={setAudienceAgeLow}*/}
                        {/*            children={"От"}*/}
                        {/*            placeholder={"0"}*/}
                        {/*            style={{*/}
                        {/*                paddingLeft: "15px",*/}
                        {/*                paddingTop: "30px",*/}
                        {/*                paddingBottom: "10px",*/}
                        {/*                width: "100%",*/}
                        {/*                height: "48px",*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        <img src={require("../assets/img/solid.png")} alt="-"/>*/}
                        {/*        <MyInput*/}
                        {/*            value={audienceAgeHigh.value || audienceAgeHigh}*/}
                        {/*            onChange={setAudienceAgeHigh}*/}
                        {/*            children={"До"}*/}
                        {/*            placeholder={"0"}*/}
                        {/*            style={{*/}
                        {/*                paddingLeft: "15px",*/}
                        {/*                paddingTop: "30px",*/}
                        {/*                paddingBottom: "10px",*/}
                        {/*                width: "100%",*/}
                        {/*                height: "48px",*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    <MySlider*/}
                        {/*        style={{marginBottom: "20px"}}*/}
                        {/*        min={0}*/}
                        {/*        max={100}*/}
                        {/*        value={[audienceAgeLow, audienceAgeHigh]}*/}
                        {/*        minDistance={1}*/}
                        {/*        onChange={(v) => {*/}
                        {/*            setAudienceAgeLow(v[0]);*/}
                        {/*            setAudienceAgeHigh(v[1]);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="CPV">
                            <div className="cpvBlock"
                                 onMouseEnter={() => setShowInfo(true)}
                                 onMouseLeave={() => setShowInfo(false)}
                            >
                                CPV
                                <img className="infoImg" src={info} alt="info"/></div>
                            {showInfo && <div className="cpvInfo">CPV - стоимость за один просмотр</div>}
                            <MyInput
                                value={cpv.value || cpv}
                                onChange={setCpv}
                                placeholder={"Введите"}
                                style={{width: "100%", height: "48px"}}
                            />
                        </div>
                    </div>
                    {/*<div className="category_right">*/}
                    {/*    <div className="category_right_header">*/}
                    {/*        <h2 className="title">Тематика проекта*</h2>*/}
                    {/*        <span className="tag_all" onClick={() => handleCategorySelect("all")}>*/}
                    {/*                Выбрать все*/}
                    {/*            </span>*/}
                    {/*    </div>*/}
                    {/*    <div className="audience_block">*/}
                    {/*    <div className="category_content">*/}
                    {/*            {*/}
                    {/*                dataBookCategory &&*/}
                    {/*                dataBookCategory.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)*/}
                    {/*                    .map(item =>(*/}
                    {/*                        <span*/}
                    {/*                            key={item?.id}*/}
                    {/*                            className={`category ${selectedItemAudience.includes(item.name) ? "selected_category" : ""*/}
                    {/*                            }`}*/}
                    {/*                            onClick={() => handleCategorySelect(item.name)}*/}
                    {/*                        >*/}
                    {/*                        {item.name}*/}
                    {/*                    </span>*/}
                    {/*                    )*/}
                    {/*                )*/}

                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    {/*<select*/}
                    {/*    className={`select ${isFocusedAudience ? "focused" : ""}`}*/}
                    {/*    onClick={handleSelectFocusAudience}*/}
                    {/*    onChange={handleOptionSelectAudience}*/}
                    {/*    onBlur={handleSelectBlur}*/}
                    {/*>*/}
                    {/*    <option value="" disabled selected style={{ color: "#0000004D" }}>*/}
                    {/*        Тематика проекта**/}
                    {/*    </option>*/}
                    {/*    <option value="all">Выбрать все</option>*/}
                    {/*    {dataBookCategory &&*/}
                    {/*        dataBookCategory.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((item) => (*/}
                    {/*            <option key={item.id} value={item.name}>*/}
                    {/*                {item.name}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*</select>*/}
                </div>

                <h3 className="select_options_bloggers_count">
                    Количество подходящих блогеров: {bloggersCount}
                </h3>
                <div className="select_options_errors">
                    {errors.map((error) => (
                        <p>{error}</p>
                    ))}
                </div>
                <div className="select_options_block_buttons">
                    <Link to="/">
                        <span classname="select_options_block_buttons_back-button">
                            Назад
                        </span>
                    </Link>
                    <Link onClick={saveTechnicalTask}>
                        <MyButton
                            text={"Продолжить"}
                            disabled={nextButtonDisabled}
                            icon={chevronRight}
                        />
                    </Link>

                </div>
                <div>
                    {
                        (!campaign ||
                            !brand ||
                            !logo ||
                            !selectedItems ||
                            !task ||
                            !selectedItemAudience ||
                            !task ||
                            !bloggersQty
                        ) && window.innerWidth > 768 &&
                        <div className="requireMessages">
                            <div className="requireMessagesHeader"><img src={alertImg} alt=""/> Для того чтобы
                                продолжить, необходимо заполнить поле:
                            </div>
                            <div className="requireMessagesText">
                                <p> {!campaign && "Название компании"} < /p>
                                <p> {!brand && "Бренд"}</p>
                                <p> {!logo && "Лого"}</p>
                                <p> {!selectedItems.length && "Площадка"}</p>
                                <p> {!task && "Задача"}</p>
                                <p> {!selectedItemAudience.length && "Тематика"}</p>
                                <p> {!bloggersQty && "Количество блогеров"}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataBookArea: state.dataBookArea,
        dataBookCity: state.dataBookCity,
        dataBookCategory: state.dataBookCategory,
        dataAppState: state.dataAppState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBookArea: () => dispatch(fetchBookArea()),
        fetchBookCity: () => dispatch(fetchBookCity()),
        fetchBookCategory: () => dispatch(fetchBookCategory()),
        setTechnicalTask: (data) => dispatch(setTechnicalTask(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectOptions);
