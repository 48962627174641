import axios from 'axios';

const axiosConfig = { baseURL: process.env.REACT_APP_BASE_URL} ;
const gptApi = axios.create(axiosConfig);

gptApi.interceptors.request.use(
  (config) => {
    // config.headers['X-Authorization'] = 'NrAm5AZlVmsbcjWr4CincKYuIaVlFYtB28c-Hq9M';
    return config;
  },
  (error) => Promise.reject(error),
);


export default gptApi;